<template >
  <div v-if="showMessage" @contextmenu.prevent="openMenu($event, message)">
    <div v-if="!message.belong" class="text-body" style="display: flex; justify-content: space-between">
      <div style="display: flex">
        <div class="audio-text-my" style="margin-right: 10px">{{ message.messageAudioLength }}′′</div>
        <img class="image-box-audio" src="@/assets/audio.png" style="cursor: pointer; margin-right: 120px" @click="startplayAudio(message, message.id)" />
      </div>
      <img class="image-box-audio" style="cursor: pointer; width: 18px" src="@/assets/wenzi.png" @click="redAudio(message, message.id)" />
    </div>
    <div v-if="message.belong" class="text-body-my" style="display: flex; justify-content: space-between">
      <img class="image-box-audio" style="cursor: pointer; margin-right: 120px; width: 18px" src="@/assets/wenzi.png" @click="redAudio(message, message.id)" />
      <div style="display: flex">
        <img class="image-box-audio" src="@/assets/audio.png" style="cursor: pointer" @click="startplayAudio(message, message.id)" />
        <div class="audio-text-my">{{ message.messageAudioLength }}′′</div>
      </div>
    </div>
    <div class="csot" v-if="message.redfy">{{ message.redtext }}</div>
  </div>
</template>
  <script>
import BenzAMRRecorder from 'benz-amr-recorder'
import KDXF from '@/Kdxf/kdxf'
export default {
  name: 'AudioMessage',
  components: {},
  props: {
    message: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showMessage: false,
      armRec: null,
      audioPlayStatus: [],
    }
  },
  mounted() {
    this.showMessage = true
  },
  methods: {
    openMenu(event,item){
        console.log("右键事件最底层")
        this.$emit('openMenu',{event:event,item:item})
    },
    redAudio(item) {
      let that = this
      if (item.redfy == 1) {
        item.redfy = 0
        that.$forceUpdate()
        return
      }
      item.redfy = 1
      if (item.redtext) {
        that.$forceUpdate()
        return
      }
      that.$forceUpdate()
      KDXF.redfile(item.messageUrl, redtext => {
        item.redtext = redtext
        console.log(item)
        that.$forceUpdate()
      })
      console.log(item.redtext)
    },
    startplayAudio(item, index) {
      if (this.audioPlayStatus[index] == 1) {
        console.log('暂停')
        this.armRec.pause()
        this.audioPlayStatus[index] = 2
        return
      } else if (this.audioPlayStatus[index] == 2) {
        console.log('继续播放')
        this.armRec.resume()
        this.audioPlayStatus[index] = 1
        return
      } else if (this.audioPlayStatus[index] == 0) {
        this.armRec.stop()
      } else {
        if (this.audioPlayStatus.length > 0) {
          this.armRec.stop()
        }
      }
      this.armRec = new BenzAMRRecorder()
      const src = item.messageUrl
      console.log('>>>>>开始播放音频', item.messageUrl)
      //初始化音频源并调用播放
      this.armRec.initWithUrl(src).then(() => {
        this.armRec.play()
        this.audioPlayStatus[index] = 1
      })
      //播放开始监听
      this.armRec.onPlay(() => {
        console.log('播放事件')
      })
      //播放结束监听
      this.armRec.onPause(() => {
        this.audioPlayStatus[index] = 2
        console.log('暂停事件')
      })
      //播放结束监听
      this.armRec.onStop(() => {
        console.log('停止事件')
        this.audioPlayStatus[index] = 0
      })
    },
  },
}
</script>
  <style scoped>
.text-body {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  background: #eeeeee;
  margin-top: 6px;
}
.text-body-my {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  background: #fff3e8;
  margin-top: 6px;
}
.text-body p {
  margin-bottom: 0px !important;
}
.text-body-my p {
  margin-bottom: 0px !important;
}
.image-box-audio {
  width: 14px;
  cursor: pointer;
}
.audio-text-my {
  font-family: PingFangSCRegular-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #999999;
  margin-left: 8px;
}
.csot-my {
  margin-top: 10px;
  margin-right: 10px;
  max-width: 400px;
}
.csot {
  margin-top: 10px;
  margin-left: 10px;
  max-width: 400px;
}
</style>