<template>
     <div>
        <div class="imgheader-order">
          <div class="imgtitle-order">邀请下单</div>
          <div class="imgtitleaction">
            <div class="close-order" style="margin-top: 16px; margin-right: 0px;" @click="closeProduct()"></div>
          </div>
        </div>
        <div class="imgbox-order">
            <div class="imgbox-order-left">
              <div class="action-item"><input class="login-input" maxlength="20" v-model="seachvalue" placeholder="搜索在售商品" /><div class="seach-button" @click="seachList"></div></div>
              <div class="z-tabs">
                <div class="z-tab" :class="orderType == 1 ?'z-tab-active' :''" @click="setOstill(1)">设计商品</div>
                <div class="z-tab" :class="orderType == 0 ?'z-tab-active' :''" @click="setOstill(0)">普通商品</div>
              </div>
              <div class="imbox-list">
                <div class="plist-item" v-for="item in productList" :key="item.id">
                  <div class="item-image">
                    <img :src="item.picUrl">
                  </div>
                  <div class="item-body">
                     <div class="item-body-name">{{item.name}}</div>
                     <div class="item-body-desc">
                        <div class="item-body-amount">￥ {{(item.price/100).toFixed(2)}}</div>
                        <div :class="item.select?'item-body-button':'item-body-button-active'" @click="setProduct(item)">{{item.select?'已添加':'添加'}}</div>
                     </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="imgbox-order-right">
                <div class="right-title">
                   <div class="right-title-text">下单清单</div>
                   <div class="right-title-action" @click="clearProduct">清空</div>
                </div>
                <div class="select-list">
                   <div class="select-item" v-for="item in productList" :key="item.id">
                      <div v-if="item.select" style="display: flex;justify-content: space-between;">
                        <div class="select-image-icon" @click="clearNum(item)">
                          <img src="~@/assets/select-icon.png">
                        </div>
                        <div class="select-image">
                          <img :src="getSpcePicurl(item)">
                        </div>
                        <div style="margin-left:10px; display: flex;flex-direction: column;justify-content: space-between;">
                          <div class="item-body-name">{{item.name}}</div>
                          <div style="font-size:12px;">
                            <select v-model="item.spuId" class="box-select">
                              <option v-for="spce in item.spces" :key="spce.id" :value="spce.id">{{spce.text}}</option>
                            </select>
                          </div>
                          <div style="display: flex; justify-content: space-between;">
                            <div>￥ {{(getSpcePicAmount(item)/100).toFixed(2)}}/㎡</div>
                            <div>
                              <input class="box-input" min="1" v-model="item.addnum"/>
                            </div>
                          </div>
                        </div>
                      </div>
                   </div>
                </div>
                <div class="right-foot">
                    <div class="right-foot-num">已选{{getSelectNum()}}件</div>
                    <div>
                      <div class="right-foot-amount">合计<span style="font-size:10;color:#ED2616;">￥</span><span style="font-size:16;color:#ED2616;font-weight: bold;">{{getSelectAmount()}}</span></div>
                      <div class="right-foot-button" @click="comSend">发送</div>
                    </div>
                    
                </div>
            </div>
        </div>
     </div>
</template>
<script>
import { ipcApiRoute, requestHttpGet, requestHttpPostByOnline } from '@/api/main'
import storage from 'store2'
import apiUrl from '@/utils/config'
export default {
  name: 'InviteOrder',
  components: {
    
  },
  props: {
    userId: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    demandId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
        orderType:1,
        seachvalue:"",
        productList:[],

    }
  },
  mounted() {
    this.getProduct()
  },
  methods: {
    seachList() {
      this.getProduct()
    },
    closeProduct() {
      this.$emit('close')
    },
    setProduct(item) {
      item.select = !item.select
    },
    clearNum(item) {
      item.select = false
    },
    getSelectAmount() {
      let p = 0
      this.productList.forEach(e => {
        if (e.select) {
          e.spces.forEach(s => {
            if (e.spuId == s.id) {
              p = p + parseInt(s.price) * parseInt(e.addnum)
            }
          })
        }
      })
      if (p == 0) {
        return 0
      } else {
        return (p / 100).toFixed(2)
      }
    },
    clearProduct() {
      this.productList.forEach(e => {
        e.select = false
        e.addnum = 1
      })
    },
    getSelectNum() {
      let p = 0
      this.productList.forEach(e => {
        if (e.select) {
          p = parseInt(p) + parseInt(e.addnum)
        }
      })
      return p
    },
    getSpcePicAmount(item) {
      let amount = ''
      item.spces.forEach(e => {
        if (e.id == item.spuId) {
          amount = e.price
        }
      })
      return amount
    },
     getSpcePicurl(item) {
      let pic = ''
      item.spces.forEach(e => {
        if (e.id == item.spuId) {
          pic = e.picUrl
        }
      })
      return pic
    },
    setOstill(e) {
      this.orderType = e
      this.getProduct()
    },
    async getProduct() {
      let obj = {}
      if (this.seachvalue != '') {
        obj.name = this.seachvalue
      }
      obj.shopType = storage.get('shopType')
      let res = await requestHttpPostByOnline(apiUrl + '/admin-api/product/spu/get-list-pc-im', obj)
      this.productList = []
      res.data.forEach(e => {
        e.select = false
        e.addnum = 1
        e.spces = []
        e.skus.forEach(d => {
          let obj = {}
          obj.id = d.id
          obj.picUrl = d.picUrl
          obj.price = d.price
          obj.text = []
          d.properties.forEach(q => {
            obj.text.push(q.valueName)
          })
          obj.text = obj.text.join('-')
          e.spces.push(obj)
        })
        e.spuId = e.spces[0].id
        this.productList.push(e)
      })
    },
    async comSend() {
      // 邀请下单
      let param = [] // [{skuId: v.skuId,count: v.num,cartId:0}]
      let productName = ''
      let productImage = ''
      let price = 0
    
      this.productList.forEach(e => {
        if (e.select) {
          let infos = {}
          infos.skuId = e.spuId
          infos.count = e.addnum
          infos.cartId = 0
          infos.demandId = this.demandId
          param.push(infos)
          productName = e.name
          e.skus.forEach(s => {
            if (s.id == e.spuId) {
              price = price + parseInt(e.addnum) * s.price
              productImage = s.picUrl
            }
          })
        }
      })
      if(price<=0){
        return 
      }
      let obj = {
        orderId: '', //订单id
        image: productImage, //商品图片
        name: productName, //商品名称
        titleName: '已帮您选择好商品规格。',
        price: price, //金额
        designerId: 0, //退款状态
        tenantId: 0,
        ext: JSON.stringify(param),
        type: 'selectOrder',
      }
      let extType = 0
      let ext = {}
      //发送自定义订单消息
      this.$ImSdk.sendCustomMessages(this.userId, this.type, obj, this, ext, extType)
      this.closeProduct()
    },
  }
}
</script>
<style scoped>
.imgdialog-order {
  border-radius: 10px;
}
.imgheader-order {
  font-weight: bold;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.imgbox-order {
  display: flex;
  justify-content: space-between;
  height: 640px;
}
.imgbox-order-left {
  width: 250px;
  border-right: 1px solid #eee;
}
.imgbox-order-right {
  height: 640px;
  width: 350px;
}
.imgtitleaction {
  position: absolute;
  right: 16px;
  top: 0px;
}
.close-order {
  width: 18px;
  height: 18px;
  background-image: url('~@/assets/main-close.png');
  background-size: 100%;
  margin-top: 16px;
  margin-left: 5px;
  -webkit-app-region: no-drag;
}
.action-item {
  margin-top: 16px;
  margin-left: 0px;
  margin-right: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}
.login-input {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  width: 220px;
  height: 29px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 29px;
  outline: none;
  color: #999;
}
.login-input:focus {
  outline: none;
  border: 1px solid #d8d8d8;
}
.login-input::placeholder {
  color: #999999;
}
.seach-button {
  background-image: url('~@/assets/seach.png');
  background-size: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 195px;
  cursor: pointer;
}
.z-tabs {
  display: flex;
  justify-content: flex-start;
}
.z-tab {
  border-radius: 60px;
  opacity: 1;
  background: rgba(136, 136, 136, 0.1);
  font-family: PingFang SC;
  font-size: 11px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  color: #222222;
  padding: 4px 10px;
  margin-right: 10px;
  cursor: pointer;
}
.z-tab-active {
  border-radius: 60px;
  opacity: 1;
  background: rgba(237, 38, 22, 0.1);
  font-family: PingFang SC;
  font-size: 11px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ed2616;
  padding: 4px 10px;
  margin-right: 10px;
  cursor: pointer;
}
.imbox-list {
  height: 540px;
  overflow-y: auto;
}
.plist-item {
  background: #f7f7f7;
  border-radius: 4px;
  height: 80px;
  margin-right: 16px;
  margin-top: 16px;
  padding: 10px;
  display: flex;
}
.item-image {
  width: 60px;
  height: 60px;
}
.item-image img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
}
.item-body {
  margin-left: 10px;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item-body-name {
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-body-desc {
  display: flex;
  justify-content: space-between;
}
.item-body-amount {
  font-size: 12px;
}
.item-body-button {
  font-size: 12px;
  border-radius: 60px;
  opacity: 1;
  background: rgba(136, 136, 136, 0.1);
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  color: #fff;
  padding: 4px 10px;
  cursor: pointer;
}
.item-body-button-active {
  font-size: 12px;
  border-radius: 60px;
  opacity: 1;
  background: rgba(237, 38, 22, 0.1);
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ed2616;
  padding: 4px 10px;
  cursor: pointer;
}
.right-title {
  height: 50px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
}
.right-title-action {
  color: #ed2616;
}
.select-list {
  height: 560px;
}
.select-item {
  margin-left: 10px;
  margin-top: 10px;
}
.select-image {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-left: 10px;
}
.select-image img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.select-image-icon {
  margin-top: 40px;
}
.box-select {
  border: none;
  width: 120px;
  outline: none;
}
.box-select::focus {
  border: none;
  width: 80px;
}
.box-input {
  border: 1px solid #eee;
  width: 50px;
  outline: none;
  text-align: center;
}
.box-input::focus {
  border: none;
  width: 80px;
}
.right-foot {
  height: 50px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
.right-foot-num {
  font-size: 12px;
  margin-top: 10px;
}
.right-foot-amount {
  font-size: 12px;
  margin-top: 10px;
  margin-right: 80px;
}
.right-foot-button {
  width: 80px;
  height: 36px;
  opacity: 1;
  background: #ed2616;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  line-height: 36px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
}
</style>