<template >
  <div v-if="showMessage" @contextmenu.prevent="openMenu($event, message)">
    <div :class="message.belong ? 'text-body-my file-box' : 'text-body file-box'">
      <div>
        <div>{{ message.messageFileName }}</div>
        <div style="color: #999999">
          {{ fileSizeFormat(message.messageFileLength) }}
        </div>
      </div>
      <div style="margin-left: 125px">
        <img class="image-box-file" :src="getFileIcon(message.messageFileName)" />
      </div>
    </div>
  </div>
</template>
<script>
import fileSizeFormat from '@/utils/fileSizeFormat'
export default {
  name: 'FileMessage',
  components: {
    
  },
  props: {
    message: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showMessage: false,
      fileSizeFormat: fileSizeFormat,
    }
  },
  mounted() {
    this.showMessage = true
  },
  methods: {
    openMenu(event,item){
        console.log("右键事件最底层")
        this.$emit('openMenu',{event:event,item:item})
    },
    getFileIcon(item) {
      let fileExtension = item.substring(item.lastIndexOf('.') + 1)
      if (fileExtension == 'zip' || fileExtension == 'ZIP') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/zip.png'
      }
      if (fileExtension == 'doc' || fileExtension == 'docx' || fileExtension == 'DOC' || fileExtension == 'DOCX') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/word.png'
      }
      if (fileExtension == 'xls' || fileExtension == 'xlsx' || fileExtension == 'XLS' || fileExtension == 'XLSX') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/xls.png'
      }
      if (fileExtension == 'txt' || fileExtension == 'TXT') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/txt.png'
      }
      if (fileExtension == 'pdf' || fileExtension == 'PDF') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/pdf.png'
      }
      if (fileExtension == 'ppt' || fileExtension == 'pptx' || fileExtension == 'PPT' || fileExtension == 'PPTX') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/ppt.png'
      }
      if (fileExtension == 'dwg' || fileExtension == 'dxf' || fileExtension == 'DWG' || fileExtension == 'DXF') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/cad.png'
      }
      if (fileExtension == 'png') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/png.png'
      }
    }
  },
}
</script>
<style scoped>
.text-body {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  background: #eeeeee;
  margin-top: 6px;
}
.text-body-my {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  background: #fff3e8;
  margin-top: 6px;
}
.text-body p {
  margin-bottom: 0px !important;
}
.text-body-my p {
  margin-bottom: 0px !important;
}
.image-box-file {
  width: 54px;
}
.file-box {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
</style>