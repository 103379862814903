<template>
    <div style="width: 100%; display:flex;">
        <div class="forward-left">
          <div class="forward-search">
            <img class="forward-search-icon" src="https://cdn.binliyoujia.com/static/message/forward-search-pc.png"/>
            <input class="forward-search-input" v-model="forwardSearchValue" @input="searchHanlde" placeholder="搜索"/>
          </div>
          <div class="forward-desc">最近聊天</div>
          <div class="forward-session-list">
            <div class="forward-session-item" v-for="item in sessionList" :key="'session'+item.id" @click="setForwardItem(item)" v-if="item.isShow">
              <img class="forward-session-icon" :src="item.isSelect?'https://cdn.binliyoujia.com/static/message/forward-checked-pc.png':'https://cdn.binliyoujia.com/static/message/forward-check-pc.png'"></img>
              <img v-if="item.type == 'chat'" class="forward-session-avatar" :src="item.otherMember.avatar"/>
              <img v-if="item.type == 'group'" class="forward-session-avatar" :src="item.image"/>
              <div v-if="item.type == 'chat'" class="forward-session-nickname">{{item.otherMember.nickname ? item.otherMember.nickname:item.otherMember.id }}</div>
              <div v-if="item.type == 'group'" class="forward-session-nickname">{{ item.noteName != null ?item.noteName:item.name }}</div>
            </div>
          </div>
        </div>
        <div class="forward-right">
          <div class="forward-right-title">
            <div class="forward-right-text">分别转发给</div>
            <img style="width:16px;height:16px;" src="https://cdn.binliyoujia.com/static/message/close-pc.png" @click="closeForwardDailog"/>
          </div>
          <div class="forward-session-list" style="height:200px;">
            <div class="forward-session-item" v-for="item in sessionList" :key="'selectSession'+item.id" v-if="item.isSelect" style="justify-content:space-between;">
              <div style="display:flex;align-items:center;">
                <img v-if="item.type == 'chat'" class="forward-session-avatar" :src="item.otherMember.avatar" style="margin-left:0px;"/>
                <img v-if="item.type == 'group'" class="forward-session-avatar" :src="item.image" style="margin-left:0px;"/>
                <div v-if="item.type == 'chat'" class="forward-session-nickname">{{item.otherMember.nickname ? item.otherMember.nickname:item.otherMember.id }}</div>
                <div v-if="item.type == 'group'" class="forward-session-nickname">{{ item.noteName != null ?item.noteName:item.name }}</div>
              </div>
              <img style="width:16px;height:16px;" src="https://cdn.binliyoujia.com/static/message/quote-close.png" @click="setForwardItem(item)"/>
            </div>
          </div>
          <div class="forward-message" @click="openForwardMessageListDailog">
            <div class="forward-message-text">{{forwardMessageTitle}}</div>
            <img style="width:12px;height:12px;" src="https://cdn.binliyoujia.com/static/message/forward-right.png">
          </div>
          <div class="forward-message-input-bg">
            <input class="forward-message-input" v-model="dialogMessageValue" placeholder="留言"/>
          </div>
          <div class="forward-message-buttons">
              <button class="forward-message-button-ok" @click="sendForwardMessage">发送</button>
              <button class="forward-message-button-calce" @click="closeForwardDailog">取消</button>
          </div>
        </div>
    </div>
</template>
<script>
import storage from 'store2'
export default {
  name: 'ForwardDailog',
  components: {
    
  },
  props: {
    userId: {
      type: String,
      default: "",
    },
    fromName: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    forwardMessageTitle:{
      type: String,
      default: "",
    },
    forwardSendType:{
        type: Number,
        default: 0,
    },
    forwardType:{
        type: Number,
        default: 0,
    },
    forwardList:{
        type:Array,
        default:() => []
    }
  },
  data() {
    return {
        forwardSearchValue:"",
        sessionList:[],
        dialogMessageValue:"",
        selectSessionList:[]
    }
  },
  mounted() {
    this.getSessionList()
  },
  methods: {
    setForwardItem(item) {
      item.isSelect = !item.isSelect
      this.$forceUpdate()
    },
    closeForwardDailog(){
        this.$emit('close')
    },
    openForwardMessageListDailog(){
        this.$emit('openForwardMessageListDailog')
    },
    //获取会话列表
    async getSessionList() {
      //获取会话列表
      this.sessionList = []
      let chatList = await this.$ImSdk.getlocalChat(this)
      let groupList = await this.$ImSdk.getlocalGroup(this)
      this.sessionList = this.sessionList.concat(...chatList)
      this.sessionList = this.sessionList.concat(...groupList)
      this.sessionList = this.$ImSdk.updateTopSort(this.sessionList)
      this.sessionList.forEach(e => {
        e.isSelect = false
        e.isShow = true
      })
      console.log(this.sessionList)
    },
    searchHanlde(v) {
      if (this.forwardSearchValue == '') {
        this.sessionList.forEach(e => {
          e.isShow = true
        })
      }
      this.sessionList.forEach(e => {
        if (e.type == 'chat') {
          let str = e.otherMember.nickname
          if (str.indexOf(this.forwardSearchValue) >= 0) {
            e.isShow = true
          } else {
            e.isShow = false
          }
        }
        if (e.type == 'group') {
          let str = e.noteName ? e.noteName : e.name
          if (str.indexOf(this.forwardSearchValue) >= 0) {
            e.isShow = true
          } else {
            e.isShow = false
          }
        }
      })
    },
    sendForwardMessage() {
      console.log(this.sessionList)
      this.selectSessionList = []
      this.sessionList.forEach(e => {
        if (e.isSelect) {
          let obj = {}
          if (e.type == 'chat') {
            obj.toId = e.otherMember.id
          }
          if (e.type == 'group') {
            obj.toId = e.id
          }
          obj.type = e.type
          this.selectSessionList.push(obj)
        }
      })
      console.log(this.forwardSendType)
      if (this.forwardSendType == 0) {
        console.log('转发消息', this.forwardList)
        this.selectSessionList.forEach(e => {
          this.forwardList.forEach(press=>{
            let extInfo = {}
            if(press.extType == 1 || press.extType == 0){
                extInfo = press.messageExt
            }
            if(press.extType == 2){
                extInfo = {exts:press.messageExt}
            }
            if (press.messageType == 'txt') {
              this.$ImSdk.sendTextMessage(e.toId, e.type, press.messageMsg, this, extInfo, press.extType)
            }
            if (press.messageType == 'img') {
              this.$ImSdk.sendForwardImagesMessage(e.toId, e.type, press, extInfo, press.extType)
            }
            if (press.messageType == 'audio') {
              this.$ImSdk.sendForwardAudioMessage(e.toId, e.type, press, extInfo, press.extType)
            }
            if (press.messageType == 'video') {
              this.$ImSdk.sendForwardVideoMessage(e.toId, e.type, press, extInfo, press.extTyp)
            }
            if (press.messageType == 'file') {
              this.$ImSdk.sendForwardFileMessage(e.toId, e.type, press, extInfo, press.extType)
            }
            if (press.messageType == 'custom') {
              this.$ImSdk.sendCustomMessages(e.toId, e.type, press.messageCustomExt, extInfo, this, press.extType)
            }
          })
          if (this.dialogMessageValue != '') {
            this.$ImSdk.sendTextMessage(e.toId, e.type, this.dialogMessageValue, this, {}, 0)
          }
        })
      } else {
        let forwardTitle = ''
        let ext = { exts: this.forwardList }
        let extType = 2
        if (this.type == 'group') {
          forwardTitle = this.fromName + '群聊的聊天记录'
        }
        if (this.type == 'chat') {
          forwardTitle = storage.get('UserName') + '与' + this.fromName + '的聊天记录'
        }
     
        this.selectSessionList.forEach(e => {
          this.$ImSdk.sendForwardMessage(e.toId, e.type, forwardTitle, this, ext, extType)
          if (this.dialogMessageValue != '') {
            this.$ImSdk.sendTextMessage(e.toId, e.type, this.dialogMessageValue, this, ext, extType)
          }
        })
      }
      this.closeForwardDailog()
      this.closeForward()
    },
    closeForward(){
        this.$emit('closeForward')
    }
  }
}
</script>
<style scoped>
.forward-dailog {
  border-radius: 10px;
  display: flex;
}
.forward-left {
  width: 216px;
  background-color: #fff;
  border-right: 1px solid #eee;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.forward-search {
  border-radius: 5px;
  background-color: #eee;
  margin-left: 16px;
  margin-right: 16px;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
}
.forward-search-icon {
  width: 15px;
  height: 15px;
}
.forward-search-input {
  margin-left: 6px;
  color: #9c9c9c;
  font-size: 12px;
  border: none;
  background-color: #eee;
  outline: none;
  width: 180px;
}

.forward-desc {
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  color: #444;
  font-size: 12px;
}
.forward-session-list {
  margin-top: 8px;
  height: 300px;
  overflow-y: auto;
}
.forward-session-item {
  padding: 8px 18px 8px 18px;
  display: flex;
  align-items: center;
}
.forward-session-item:hover {
  background-color: rgba(237, 38, 22, 0.05);
}
.forward-session-icon {
  width: 14px;
  height: 14px;
}
.forward-session-avatar {
  width: 28px;
  height: 28px;
  border: 1px solid #979797;
  margin-left: 8px;
  border-radius: 50%;
}
.forward-session-nickname {
  color: #222;
  font-size: 10px;
  margin-left: 6px;
}
.forward-right {
  width: 256px;
  background-color: #fff;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.forward-right-title {
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forward-right-text {
  color: #222;
  font-size: 14px;
}
.forward-message {
  height: 30px;
  margin-left: 16px;
  margin-right: 16px;
  border-left: 4px solid #d8d8d8;
  padding-left: 12px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  margin-top: 16px;
}
.forward-message-text {
  color: #666;
  font-size: 12px;
}
.forward-message-input-bg {
  margin-left: 16px;
  margin-right: 16px;
  height: 29px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  padding: 0px 10px 0px 10px;
  background-color: #fff;
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.forward-message-input {
  color: #9c9c9c;
  font-size: 12px;
  border: none;
  background-color: #fff;
  outline: none;
  width: 180px;
}
.forward-message-buttons {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forward-message-button-calce {
  width: 108px;
  height: 31px;
  border-radius: 4px;
  background: #eeeeee;
  font-size: 12px;
  color: #222222;
  border: none;
}
.forward-message-button-ok {
  width: 108px;
  height: 31px;
  border-radius: 4px;
  background: #ed2616;
  font-size: 12px;
  color: #fffdfd;
  border: none;
}
</style>