<template>
  <div>{{ getIsBackName() }}撤回了一条消息 <span style="color: #166ded; margin-left: 32rpx" v-if="message.messageType == 'txt' && message.belong" @click="setValues()">重新编辑</span></div>
</template>
<script>
import storage from 'store2'
export default {
  name: 'BackMessage',
  props: {
    message: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    getIsBackName() {
      let name = ''
      if (this.message.messageFrom == storage.get('userId')) {
        return '你'
      }
      this.message.members.forEach(e => {
        if (this.message.messageFrom == e.id) {
          name = e.nickname
        }
      })
      return name
    },
    setValues() {
      console.log('撤回')
      this.$emit('setValues', this.message.messageMsg)
    },
  },
}
</script>