<template>
  <div id="app-menu" style="display: flex">
    <div theme="light" class="layout-sider">
      <div class="list">
        <div class="action-item">
          <input class="login-input" maxlength="20" v-model="seachvalue" placeholder="搜索" />
          <div class="seach-button" @click="seachList"></div>
          <div class="action-edit" :class="add ? 'action-edit-avicat' : ''" @click="setAdd"></div>
        </div>
        <div class="list-plant" ref="listplant" id="listplant" @mousedown="down" @mousemove="move" @mouseup="up" @mouseleave="leave">
          <div class="list-item" :class="itemId == item.id ? 'list-item-active' : ''" v-for="item in listItem" :key="item.id" @click="setItem(item)" @contextmenu.prevent="openMenu($event, item)" v-if="item.isShow">
            <div class="item-header">
              <img class="item-header-img" v-if="pageid == 1" :src="item.otherMember ? item.otherMember.avatar : ''" />
              <img class="item-header-img" v-if="pageid == 2" :src="item.image" :key="item.id" />
              <div class="new-dot" v-if="item.unRead > 0"></div>
            </div>
            <div class="item-body">
              <div class="item-nickname">
                <div v-if="pageid == 2" style="display: flex">
                  <span class="groupname">{{ item.noteName != null ? item.noteName : item.name }}</span>
                  <span>({{ item.members.split(',').length }})</span>
                </div>
                <div v-if="pageid == 1">{{ item.noteName != null ? item.noteName : item.otherMember ? item.otherMember.nickname ? item.otherMember.nickname : item.otherMember.id : '用户已不存在' }}</div>
                <div class="time">{{ item.message ? getCreateTimeText(item.message.createTime) : '' }}</div>
              </div>
              <div class="item-dec">
                <div class="item-dec-text">{{ item.message ? item.message.messageMsg : '暂无消息' }}</div>
                <div class="item-dot" v-if="item.unRead > 0">{{ item.unRead }}</div>
                <div class="item-mdr" v-if="item.isDisturb">
                  <img src="~@/assets/mdr.png" />
                </div>
                <div class="item-mdr-zd" v-if="item.isTop">置顶</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="right" class="right-click" :class="this.pageid == 3 ? 'right-click-fileds' : ''" :style="this.pageid == 2 ? 'height:123px;' : ''" ref="rightClick">
      <div v-if="this.pageid != 3" class="p-text" @click="aciton(1)">{{ getZdName() }}</div>
      <div v-if="this.pageid != 3" class="p-text" @click="aciton(2)">标记已读</div>
      <div v-if="this.pageid != 3" class="p-text" @click="aciton(3)">{{ getMdName() }}</div>
      <div v-if="this.pageid != 3 && this.pageid != 2" class="p-text" @click="aciton(6)">{{ this.pageid == 2 ? getActionName() : '添加备注' }}</div>
      <div v-if="this.pageid != 3" class="f-splt"></div>
      <div v-if="this.pageid != 3" class="p-text" @click="aciton(4)">{{ this.pageid == 2 ? getActionName() : '删除聊天' }}</div>
    </div>
    <div v-if="add" class="add-plant">
      <div class="p-text" @click="openFapl(1)">发起会话</div>
      <div class="p-text" @click="openFapl(2)">创建群组</div>
    </div>
    <div class="message-plant">
      <Message ref="messagePlant" :uid="itemId" :usId="userId" :pId="pageid" :chatType="type" @sendM="updateList" @updateInfos="updateInfos" @gotoGroup="gotoGroup"></Message>
    </div>
    <div v-show="add || right" class="mb" @click="closeMb" ></div>
    <a-modal v-model="visible" title="发起会话" :footer="null" width="244px" :centered="true">
      <div class="ftitle">用户手机号码</div>
      <div class="finput">
        <input class="login-input-f" v-model="fuserId" />
      </div>
      <div style="display: flex; justify-content: space-between">
        <button class="login-button-queren" @click="okAdd">发起</button>
        <button class="login-button-quxiao" @click="ceacl">取消</button>
      </div>
    </a-modal>

    <a-modal v-model="groupvisible" :title="null" :footer="null" :closable="false" width="390px" :centered="true">
      <div class="grouptitle">
        <img class="groupback" src="~@/assets/back.png" v-if="groupindex == 2" @click="backgroup" />
        <div class="groupback" v-if="groupindex == 1"></div>
        <div class="grouptitle-text">创建群组</div>
        <img class="groupclose" src="@/assets/main-close.png" @click="ceaclCreateGroup" />
      </div>
      <div class="groupline"></div>
      <div v-if="groupindex == 1" style="margin-top: 16px">
        <div class="ftitle">创建群组</div>
        <div class="finput">
          <input class="login-input-f-ix" v-model="groupname" />
        </div>
        <div class="ftitle" style="margin-top: 16px">群简介</div>
        <div class="finput">
          <textarea class="login-input-f-ex" v-model="groupdec"></textarea>
        </div>
      </div>
      <div v-if="groupindex == 2" style="margin-top: 16px; display: flex">
        <div class="group-left">
          <div class="seachGroupUser">
            <input class="seachgroupuser-input" maxlength="20" v-model="seachContactsValue" placeholder="搜索" />
            <div class="seach-button-group" @click="seachContactsList"></div>
          </div>
          <div class="ftitle" style="margin-top: 8px">群成员</div>
          <div class="group-user-list">
            <a-collapse v-model="activeKey" :expand-icon-position="'right'">
              <a-collapse-panel key="1" header="最近联系人">
                <a-checkbox-group v-model="lsuserGroups" @change="changeGroupUsers">
                  <div v-for="item in hhlist" :key="item.id" v-if="item.isShow">
                    <a-checkbox :value="item">{{ item.nickname ? item.nickname : item.id }}</a-checkbox>
                  </div>
                </a-checkbox-group>
                <template #extra><setting-outlined @click="handleClickUser" /></template>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </div>
        <div class="group-right">
          <div class="ftitle" style="margin-top: 0px">已选联系人</div>
          <div class="useUser" v-for="(item, index) in useUserList" :key="item.id">
            <div class="useUser-name">{{ item.nickname ? item.nickname : item.id }}</div>
            <img class="useUser-icon" src="@/assets/user-delete.png" @click="deleteUserForUserList(index)" />
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <button class="login-button-quxiao" @click="ceaclCreateGroup">取消</button>
        <button class="login-button-queren" @click="okAddCreateGroup">{{ groupindex == 1 ? '下一步' : '创建群组' }}</button>
      </div>
    </a-modal>

    <a-modal v-model="addFiledsvisible" :title="pageid == 2 ? '群组请求' : '好友请求'" :footer="null" width="244px" :centered="true">
      <div class="ftitle" style="color: #222">{{ vbtitle }}</div>
      <div style="display: flex; justify-content: space-between">
        <button class="login-button-queren" @click="okAddFileds">同意</button>
        <button class="login-button-quxiao" @click="ceaclFileds">拒绝</button>
      </div>
    </a-modal>
    <a-modal v-model="notePlat" title="修改用户备注" :footer="null" width="244px" :centered="true">
      <div class="ftitle" style="color: #222">用户备注</div>
      <div style="margin-top: 10px">
        <a-input v-model="userNote"></a-input>
      </div>
      <div style="display: flex; justify-content: space-between">
        <button class="login-button-queren" @click="okAddNote">确定</button>
        <button class="login-button-quxiao" @click="ceaclNote">取消</button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { EaseChatClient } from '@/IM/initwebsdk'
import storage from 'store2'
import { message } from 'ant-design-vue'
import dateFormaterSession from '@/utils/dateFormaterSession'
import { Modal } from 'ant-design-vue'
import { notification } from 'ant-design-vue'
import { usePlayRing } from '@/hooks'
import { ConversationUtils } from '@/utils/ConversationUtils'
import { MessageUtils } from '@/utils/MessageUtils'
import { ipcApiRoute, requestHttpGet, requestHttpPostByOnline } from '@/api/main'
import apiUrl from '@/utils/config'
import Message from '@/components/Message.vue'
var titleInit = document.title,
  isShine = true
window.onfocus = function () {
  isShine = false
}
window.onblur = function () {
  isShine = true
}
// for IE
document.onfocusin = function () {
  isShine = false
}
document.onfocusout = function () {
  isShine = true
}
export default {
  components: {
    Message,
  },
  props: {
    pId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      seachvalue: '',
      add: false,
      right: false,
      flag: false,
      listItem: [],
      downY: 0,
      itemId: '',
      scrollTop: 0,
      acitonId: 0,
      pageid: 1,
      visible: false,
      fuserId: '',
      ginfo: null,
      newFileds: [],
      newGroups: [],
      addFiledsvisible: false,
      vbtitle: '',
      filedsItem: null,
      type: 'singleChat',
      acitonItems: null,
      groupvisible: false,
      groupname: '',
      groupdec: '',
      grouptype: 1,
      groupclass: 1,
      groupindex: 1,
      activeKey: '1',
      lsuserGroups: [],
      hyuserGroups: [],
      hylist: [],
      hhlist: [],
      useUserList: [],
      notePlat: false,
      userNote: '',
      userNoteId: '',
      userId: '0',
      seachContactsValue: '',
    }
  },
  created() {},
  mounted() {
    console.log('值', this.pId)
  },
  destroyed() {
    this.$bus.$off('im.all')
    this.$bus.$off('im.allSessionNewMessage')
    this.$bus.$off('im.allJionGroup')
    this.$bus.$off('im.allLeaveGroup')
    this.$bus.$off('im.allDeleteGroup')
    this.$bus.$off('im.allUpdateGroup')
    this.$bus.$off('im.allSessionNewSession')
    this.$bus.$off('im.allShowChat')
  },
  methods: {
    async initLeft() {
      this.menuHandle()
    },
    async jionGroup(e) {
      if (e.message) {
        e.message.createTime = new Date(e.message.createTime).getTime()
      }
      //是否是群组列表
      if (this.pageid == 2) {
        //创建群组会话

        this.listItem.unshift(e)
        this.listItem = this.$ImSdk.updateTopSort(this.listItem)
      }
      //更新本地会话数据
      let res = await this.$messageDb.getItem('grouplist')
      res.unshift(e)
      res = this.$ImSdk.updateTopSort(res)
      await this.$messageDb.setItem('grouplist', res)
      //通知弹窗
      this.noteMessage(e.image, '加入群组成功', e.name, e)
    },
    async leaveGroup(e) {
      console.log(e)
      //是否是自己离开群组
      let isMeLeave = false
      e.members.split(',').forEach(u => {
        if (u == storage.get('userId')) {
          isMeLeave = true
        }
      })
      if (!isMeLeave) {
        //是否是群组列表
        if (this.pageid == 2) {
          //删除群组会话
          this.listItem = this.listItem.filter(item => item.id != e.id)
          this.listItem = this.$ImSdk.updateTopSort(this.listItem)
        }
        //更新本地会话数据
        let res = await this.$messageDb.getItem('grouplist')
        res = res.filter(item => item.id != e.id)
        res = this.$ImSdk.updateTopSort(res)
        await this.$messageDb.setItem('grouplist', res)
        //通知弹窗
        this.noteMessage(e.image, '离开群组成功', e.name, e)
      }
    },
    async deleteGroup(e) {
      console.log(e)
      //是否是群组列表
      if (this.pageid == 2) {
        //删除群组会话
        this.listItem = this.listItem.filter(item => item.id != e.id)
        this.listItem = this.$ImSdk.updateTopSort(this.listItem)
      }
      //更新本地会话数据
      let res = await this.$messageDb.getItem('grouplist')
      res = res.filter(item => item.id != e.id)
      res = this.$ImSdk.updateTopSort(res)
      await this.$messageDb.setItem('grouplist', res)
      //通知弹窗
      this.noteMessage(e.image, '群组解散', e.name)
    },
    async updateGroup(e) {
      console.log(e)
      //是否是群组列表
      if (this.pageid == 2) {
        //更新群组会话 头像
        this.listItem.forEach(m => {
          if (m.id == e.id) {
            m.image = e.image
            m.members = e.members
          }
        })
      }

      //更新本地会话数据 头像
      let res = await this.$messageDb.getItem('grouplist')
      res.forEach(m => {
        if (m.id == e.id) {
          m.image = e.image
          m.members = e.members
        }
      })
      await this.$messageDb.setItem('grouplist', res)
      this.$forceUpdate()
    },
    playAudio() {
      let playmu = true
      if (storage.has(storage.get('userId') + 'seting')) {
        let obj = JSON.parse(storage.get(storage.get('userId') + 'seting'))
        playmu = obj.newMessageNotice
      }
      console.log(playmu)
      if (!playmu) {
        return
      }
      const { isOpenPlayRing, playRing } = usePlayRing()
      if (isOpenPlayRing.value) playRing()
    },
    openDialog(image, contnet, name, item) {
      let that = this
      let playdialog = true
      let playinfo = true
      if (storage.has(storage.get('userId') + 'seting')) {
        let obj = JSON.parse(storage.get(storage.get('userId') + 'seting'))
        playinfo = obj.noticeShowDetail
        playdialog = obj.messagePopup
      }
      if (!playdialog) {
        return
      }
      //图标设置
      let headericon = this.$createElement('img', {
        attrs: {
          src: image,
        },
        style: {
          width: '48px',
          height: '48px',
          borderRadius: '50%',
          marginLeft: '-14px',
        },
      })
      //数量
      let newnum = this.$createElement('span')
      //内容
      let content = this.$createElement('span', {}, contnet)
      if (!playinfo) {
        content = name
      }
      let msg = this.$createElement('div', { style: { display: 'flex' } }, [content, newnum])
      //位移
      let wx = (window.innerWidth - 570) / 2 + 150
      console.log(wx)
      notification.open({
        onClick: function () {
          if (item.type == 'group') {
            that.$router.push({ name: 'Crowd', params: { id: 2, ir: true, itemId: item.messageSessionId } })
          } else {
            that.$router.push({ name: 'Main', params: { id: 1, ir: true, itemId: item.messageSessionId } })
          }
          notification.close(item.id)
        },
        message: name,
        description: msg,
        key: item.id,
        duration: 5,
        style: {
          width: '570px',
          height: '80px',
          position: 'relative',
          right: wx + 'px',
          margin: 0,
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        },
        icon: headericon,
      })
    },
    noteMessage(image, content, name, item) {
      this.playAudio()
      this.openDialog(image, content, name, item)
    },

    getCreateTimeText(messageTime) {
      return dateFormaterSession(messageTime)
    },
    async okAddNote() {
      console.log(this.userNote)
      console.log(this.userNoteId)
      if (this.userNoteId == '' || this.userNoteId == null) {
        //新增  apiUrl
        await requestHttpPostByOnline(apiUrl + '/admin-api/netty/im-remark/create', {
          userId: storage.get('userId').split('_')[1],
          conversationId: this.acitonItems.otherMember.id,
          name: this.userNote,
          status: 0,
        })
      } else {
        //修改 apiUrl
        await requestHttpPostByOnline(apiUrl + '/admin-api/netty/im-remark/update', {
          id: this.userNoteId,
          name: this.userNote,
          status: 0,
        })
      }
      this.acitonItems.noteName = this.userNote
      this.acitonItems.noteNameId = this.userNoteId
      //更新添加备注
      let res = await this.$messageDb.getItem('corlist')
      res.forEach(e => {
        if (e.id == this.acitonItems.id) {
          e.noteName = this.userNote
          e.noteNameId = this.userNoteId
        }
      })
      await this.$messageDb.setItem('corlist', res)
      this.notePlat = false
      this.userNote = ''
      this.userNoteId = ''
      this.$forceUpdate()
    },
    ceaclNote() {
      this.notePlat = false
      this.userNote = ''
      this.userNoteId = ''
    },
    async leaveGroupMessage(groupId) {
      let index = -1

      this.listItem.forEach((e, i) => {
        if (e.id == groupId) {
          index = i
        }
      })
      if (index >= 0) {
        this.listItem.splice(index, 1)
      }
      if (this.itemId == groupId) {
        if (this.listItem.length > 0) {
          this.setItem(this.listItem[0].id)
        } else {
          this.setItem(0)
        }
      }
      //获取数据
      let useritem = await this.$messageDb.getItem('corlist')
      let groupitem = await this.$messageDb.getItem('grouplist')
      console.log(useritem)
      console.log(groupId)
      useritem.forEach((e, i) => {
        if (e.id == groupId) {
          useritem.splice(i, 1)
        }
      })
      groupitem.forEach((e, i) => {
        if (e.id == groupId) {
          groupitem.splice(i, 1)
        }
      })
      await this.$messageDb.setItem('corlist', useritem)
      await this.$messageDb.setItem('grouplist', groupitem)
    },
    gotoGroup(e) {
      this.$router.push({ name: 'Crowd', params: { id: 2, ir: true, itemId: e } })
    },
    updateInfos(obj) {
      if (obj.r == 'zd') {
        console.log('更新置顶状态', obj.id)
        console.log('更新置顶状态', obj.value)
        this.setZd(obj.value, obj.id)
      }
      if (obj.r == 'mdr') {
        //免打扰
        console.log('更新免打扰状态', obj.id)
        console.log('更新免打扰状态', obj.value)
        this.setMdr(obj.value, obj.id)
      }
      if (obj.r == 'qz') {
        //群组
        console.log('更新群组状态', obj.id)
        console.log('更新群组状态', obj.value)
        this.leaveGroupMessage(obj.id)
      }
      if (obj.r == 'note') {
        //群组
        console.log('更新群组状态', obj.id)
        console.log('更新群组状态', obj.value)
        console.log('更新群组状态', obj.otherId)
        this.upGroupNote(obj.id, obj.value, obj.otherId)
      }
    },
    async upGroupNote(groupId, value, otherId) {
      this.listItem.forEach((e, i) => {
        if (e.id == groupId) {
          e.noteName = value
          e.noteNameId = otherId
        }
      })
      let res = await this.$messageDb.getItem('grouplist')
      res.forEach(e => {
        if (e.id == groupId) {
          e.noteName = value
          e.noteNameId = otherId
        }
      })
      await this.$messageDb.setItem('grouplist', res)
      this.$forceUpdate()
    },
    //获取免打扰状态
    getMdName() {
      if (this.acitonItems.isDisturb) {
        return '取消免打扰'
      } else {
        return '设置免打扰'
      }
    },
    //获取置顶状态
    getZdName() {
      //会话置顶
      if (this.acitonItems.isTop) {
        return '取消置顶'
      } else {
        return '置顶'
      }
    },
    //根据群信息获取操作名称
    getActionName() {
      if (this.acitonItems.owner == storage.get('userId')) {
        return '解散群组'
      } else {
        return '离开群组'
      }
    },
    //删除所选元素
    deleteUserForUserList(i) {
      //去掉数组数据
      this.lsuserGroups.forEach((e, index) => {
        if (e.id == this.useUserList[i]['id']) {
          this.lsuserGroups.splice(index, 1)
        }
      })
      this.hyuserGroups.forEach((e, index) => {
        if (e.id == this.useUserList[i]['id']) {
          this.hyuserGroups.splice(index, 1)
        }
      })
      this.changeGroupUsers()
    },
    //数组去重
    cutarray(arr) {
      let obj = {} //obj用来记录该项重复出现了几次
      let brr = [] //brr是去重后的数组
      arr.forEach(item => {
        if (obj[item.id] == undefined) {
          obj[item.id] = 1
          brr.push(item)
        } else {
          obj[item.id] += 1
        }
      })
      return brr
    },
    //创建群组时选择群组用户事件合并数组
    changeGroupUsers() {
      let arrUsers = []
      arrUsers = this.lsuserGroups.concat(this.hyuserGroups)
      this.useUserList = this.cutarray(arrUsers)
      console.log(this.useUserList)
    },
    //获取好友
    async getFiledsList() {
      //获取好友列表
      this.hylist = []
      let res = await EaseChatClient.getContacts()
      let userinfo = await EaseChatClient.fetchUserInfoById(res.data)
      Object.keys(userinfo.data).forEach(item => {
        this.hylist.push({
          id: item,
          nickname: userinfo.data[item]['nickname'],
          avatarurl: userinfo.data[item]['avatarurl'],
        })
      })
      console.log(this.hylist)
    },
    //获取会话列表
    async getRecentContactsList() {
      //获取会话列表
      this.hhlist = await this.$ImSdk.getRecentContactsList()
      this.hhlist = this.cutarray(this.hhlist)
      this.hhlist.forEach(e => {
        e.isShow = true
      })
      console.log(this.hhlist)
    },
    handleClickUser(e) {
      e.stopPropagation()
    },
    async seachContactsList() {
      console.log('搜索')
      console.log(this.hhlist)
      if (this.seachContactsValue != '') {
        this.hhlist.forEach(e => {
          e.isShow = false
        })
        this.hhlist.forEach(e => {
          if (e.nickname.indexOf(this.seachContactsValue) >= 0) {
            e.isShow = true
          }
        })
      } else {
        this.hhlist.forEach(e => {
          e.isShow = true
        })
      }
      this.$forceUpdate()
    },
    async seachList() {
      if (this.seachvalue == '') {
        await this.menuHandle()
        return
      }
      await this.menuHandle()
      let arr = []
      this.listItem.forEach(e => {
        if (e.type == 'chat') {
          if (e.noteName != null && e.noteName.indexOf(this.seachvalue) >= 0) {
            arr.push(e)
          } else {
            if (e.otherMember != null && e.otherMember.nickname.indexOf(this.seachvalue) >= 0) {
              arr.push(e)
            }
          }
        }
        if (e.type == 'group') {
          if (e.noteName != null && e.noteName.indexOf(this.seachvalue) >= 0) {
            arr.push(e)
          } else {
            if (e.name != null && e.name.indexOf(this.seachvalue) >= 0) {
              arr.push(e)
            }
          }
        }
      })

      this.listItem = arr
    },
    async okAddCreateGroup() {
      if (this.groupindex == 1) {
        //下一步
        if (this.groupname == '') {
          message.error('请设置群组名称')
          return
        }
        this.groupindex = 2
      } else {
        //创建群组
        //获取创建群组参数
        if (this.useUserList.length == 0) {
          message.error('请选择群组成员')
          return
        }
        let obj = {
          name: this.groupname,
          introduction: this.groupdec,
          type: 'group',
          owner: storage.get('userId'),
          members: [],
        }
        console.log(this.useUserList)
        this.useUserList.forEach(e => {
          obj.members.push(e.id)
        })
        obj.members.push(storage.get('userId'))
        obj.members = obj.members.join(',')
        await this.$ImSdk.createGroup(obj)
        this.add = !this.add
        this.groupvisible = false
      }
    },
    ceaclCreateGroup() {
      this.groupindex == 1
      this.groupvisible = false
    },
    actionFlieds(item) {
      this.addFiledsvisible = true
      this.filedsItem = item
      this.vbtitle = item.status
    },
    actionGroups(item) {
      this.addFiledsvisible = true
      this.filedsItem = item
      this.vbtitle = '是否加入群组'
    },
    async okAddFileds() {
      //同意好友请求
      if (this.pageid == 2) {
        EaseChatClient.acceptGroupInvite({ invitee: storage.get('ImAcount'), groupId: this.filedsItem.gid })
        this.addFiledsvisible = false
        this.newGroups.forEach((item, index) => {
          if (item.from == this.filedsItem.from) {
            this.newGroups.splice(index, 1)
          }
        })
        storage.set('newGroupsList', JSON.stringify(this.newGroups))
        ConversationUtils.setGroupToLocal(this.filedsItem.gid, this.listItem, this, 2)
      }
      if (this.pageid == 3) {
        EaseChatClient.acceptContactInvite(this.filedsItem.from)
        this.addFiledsvisible = false
        this.newFileds.forEach((item, index) => {
          if (item.from == this.filedsItem.from) {
            this.newFileds.splice(index, 1)
          }
        })
        storage.set('newFiledsList', JSON.stringify(this.newFileds))
        this.menuHandle()
      }
    },
    ceaclFileds() {
      if (this.pageid == 2) {
        // EaseChatClient.rejectGroupJoinRequest({applicant: this.filedsItem.from, groupId: this.filedsItem.gid})
        EaseChatClient.rejectGroupInvite({ invitee: storage.get('ImAcount'), groupId: this.filedsItem.gid })
        this.addFiledsvisible = false
        this.newGroups.forEach((item, index) => {
          if (item.from == this.filedsItem.from) {
            this.newGroups.splice(index, 1)
          }
        })
        storage.set('newGroupsList', JSON.stringify(this.newGroups))
      }
      if (this.pageid == 3) {
        //拒绝好友请求
        EaseChatClient.declineContactInvite(this.filedsItem.from)
        this.addFiledsvisible = false
        //删除指定好友请求
        this.newFileds.forEach((item, index) => {
          if (item.from == this.filedsItem.from) {
            this.newFileds.splice(index, 1)
          }
        })
        storage.set('newFiledsList', JSON.stringify(this.newFileds))
      }
    },
    ceacl() {
      this.visible = false
    },
    async okAdd() {
      let that = this
      if (this.fuserId == '') {
        message.error('请填用户手机号')
        return
      }
      let obj = {
        phone: this.fuserId,
      }
      let member = await requestHttpGet(apiUrl + '/admin-api/member/user/get-user-by-phone', obj)
      await this.$ImSdk.sendTextMessage('mb_' + member.data.id, 'chat', '您好我是' + storage.get('UserName'), this)
      this.visible = false
    },
    openFapl(e) {
      if (e == 1) {
        //好友
        this.visible = true
      }
      if (e == 2) {
        //创建群组
        this.groupindex = 1
        this.groupvisible = true
        this.getRecentContactsList()
      }
    },
    backgroup() {
      this.groupindex = 1
    },
    async setZd(v, id) {
      if (this.pageid == 1) {
        let localList = await this.$messageDb.getItem('corlist')
        localList.forEach(e => {
          if (e.id == id) {
            e.isTop = v
          }
        })
        localList = this.$ImSdk.updateTopSort(localList)
        await this.$messageDb.setItem('corlist', localList)
      }
      if (this.pageid == 2) {
        let localList = await this.$messageDb.getItem('grouplist')
        localList.forEach(e => {
          if (e.id == id) {
            e.isTop = v
          }
        })
        localList = this.$ImSdk.updateTopSort(localList)
        await this.$messageDb.setItem('grouplist', localList)
      }
      this.listItem.forEach(e => {
        if (e.id == id) {
          e.isTop = v
        }
      })
      this.listItem = this.$ImSdk.updateTopSort(this.listItem)
      this.$forceUpdate()
    },
    async setMdr(v, id) {
      if (this.pageid == 1) {
        let localList = await this.$messageDb.getItem('corlist')
        localList.forEach(e => {
          if (e.id == id) {
            e.isDisturb = v
          }
        })
        await this.$messageDb.setItem('corlist', localList)
      }
      if (this.pageid == 2) {
        let localList = await this.$messageDb.getItem('grouplist')
        localList.forEach(e => {
          if (e.id == id) {
            e.isDisturb = v
          }
        })
        await this.$messageDb.setItem('grouplist', localList)
      }
      this.listItem.forEach(e => {
        if (e.id == id) {
          e.isDisturb = v
        }
      })
      this.$forceUpdate()
    },
    async aciton(v) {
      this.right = false
      let that = this
      if (v == 5) {
        console.log(this.acitonItems)
        await EaseChatClient.deleteContact(this.acitonItems.id)
        this.leaveGroupMessage(this.acitonItems.id)
      }
      if (v == 6) {
        this.userNote = this.acitonItems.noteName
        this.userNoteId = this.acitonItems.noteNameId
        this.notePlat = true
      }
      if (v == 4) {
        if (this.pageid == 1) {
          //删除会话
          await requestHttpPostByOnline(apiUrl + '/admin-api/netty/im-show/tagger', {
            userId: storage.get('userId').split('_')[1],
            conversationId: this.acitonItems.id,
            isShow: false,
          })
          if (this.acitonItems.type == 'chat') {
            let localList = await this.$messageDb.getItem('corlist')
            localList.forEach(e => {
              if (e.id == this.acitonItems.id) {
                e.isShow = false
              }
            })
            localList = this.$ImSdk.updateTopSort(localList)
            await this.$messageDb.setItem('corlist', localList)
          }
          this.listItem.forEach(e => {
            if (e.id == this.acitonItems.id) {
              e.isShow = false
            }
          })
          this.listItem = this.$ImSdk.updateTopSort(this.listItem)
          this.$forceUpdate()
        } else if (this.pageid == 2) {
          if (this.owner == storage.get('userId')) {
            //解散群组
            await this.$ImSdk.leaveGroup({ id: this.acitonItems.id })
            message.success('群解散成功！')
          } else {
            //离开群组
            await this.$ImSdk.leaveGroup({ id: this.acitonItems.id })
            message.success('群退出成功！')
          }
        }
      }
      if (v == 2) {
        //标记已读
        // await MessageUtils.sendMessageGoBack(this.acitonItems.id, this.type, this)
        let obj = {
          id: this.acitonItems.id,
          userId: storage.get('userId'),
        }
        await this.$ImSdk.setMessageIsReadBySessions(obj)
        this.acitonItems.unRead = 0
        if (this.pageid == 1) {
          let localList = await this.$messageDb.getItem('corlist')
          localList.forEach(e => {
            if (e.id == obj.id) {
              e.unRead = 0
            }
          })
          await this.$messageDb.setItem('corlist', localList)
        }
        if (this.pageid == 2) {
          let localList = await this.$messageDb.getItem('grouplist')
          localList.forEach(e => {
            if (e.id == obj.id) {
              e.unRead = 0
            }
          })
          await this.$messageDb.setItem('grouplist', localList)
        }
        this.$forceUpdate()
      }
      if (v == 3) {
        //设置消息免打扰 ????????????????????
        if (this.acitonItems.isDisturb) {
          //取消免打扰
          await requestHttpPostByOnline(apiUrl + '/admin-api/netty/im-disturb/tagger', {
            userId: storage.get('userId').split('_')[1],
            conversationId: this.acitonItems.id,
            isDisturb: false,
          })
        } else {
          //设置免打扰
          await requestHttpPostByOnline(apiUrl + '/admin-api/netty/im-disturb/tagger', {
            userId: storage.get('userId').split('_')[1],
            conversationId: this.acitonItems.id,
            isDisturb: true,
          })
        }

        if (this.acitonItems.type == 'chat') {
          let localList = await this.$messageDb.getItem('corlist')
          localList.forEach(e => {
            if (e.id == this.acitonItems.id) {
              e.isDisturb = !this.acitonItems.isDisturb
              console.log('更新免打扰')
            }
          })
          await this.$messageDb.setItem('corlist', localList)
        }
        if (this.acitonItems.type == 'group') {
          let localList = await this.$messageDb.getItem('grouplist')
          localList.forEach(e => {
            if (e.id == this.acitonItems.id) {
              e.isDisturb = !this.acitonItems.isDisturb
            }
          })
          await this.$messageDb.setItem('grouplist', localList)
        }
        this.listItem.forEach(e => {
          if (e.id == this.acitonItems.id) {
            e.isDisturb = !this.acitonItems.isDisturb
          }
        })
        this.$forceUpdate()
      }
      if (v == 1) {
        if (this.acitonItems.isTop) {
          //设置置顶
          await requestHttpPostByOnline(apiUrl + '/admin-api/netty/im-top/tagger', {
            userId: storage.get('userId').split('_')[1],
            conversationId: this.acitonItems.id,
            isTop: false,
          })
        } else {
          //取消置顶
          await requestHttpPostByOnline(apiUrl + '/admin-api/netty/im-top/tagger', {
            userId: storage.get('userId').split('_')[1],
            conversationId: this.acitonItems.id,
            isTop: true,
          })
        }

        if (this.acitonItems.type == 'chat') {
          let localList = await this.$messageDb.getItem('corlist')
          localList.forEach(e => {
            if (e.id == this.acitonItems.id) {
              e.isTop = !this.acitonItems.isTop
            }
          })
          localList = this.$ImSdk.updateTopSort(localList)
          await this.$messageDb.setItem('corlist', localList)
        }
        if (this.acitonItems.type == 'group') {
          let localList = await this.$messageDb.getItem('grouplist')
          localList.forEach(e => {
            if (e.id == this.acitonItems.id) {
              e.isTop = !this.acitonItems.isTop
            }
          })
          localList = this.$ImSdk.updateTopSort(localList)
          await this.$messageDb.setItem('grouplist', localList)
        }
        this.listItem.forEach(e => {
          if (e.id == this.acitonItems.id) {
            e.isTop = !this.acitonItems.isTop
          }
        })
        this.listItem = this.$ImSdk.updateTopSort(this.listItem)
        //更新置顶排序
        this.$forceUpdate()
      }
    },
    async setItem(e) {
      console.log(e)
      //设置会话对象 如果是单聊获取对方userId 如果是群聊获取会话id=群组id
      if (this.pageid == 1) {
        this.type = 'chat'
        this.itemId = e.id
        this.userId = e.otherMember.id
      } else {
        this.type = 'group'
        this.itemId = e.id
        this.userId = e.id + ''
      }
      //会话读取回调 消息已读
      let obj = {
        id: this.itemId,
        userId: storage.get('userId'),
      }
      let res = await this.$ImSdk.setMessageIsReadBySessions(obj)
      if (res.data) {
        e.unRead = 0
      }
      if (this.pageid == 1) {
        let localList = await this.$messageDb.getItem('corlist')
        localList.forEach(l => {
          if (l.id == obj.id) {
            l.unRead = 0
          }
        })
        await this.$messageDb.setItem('corlist', localList)
      }
      if (this.pageid == 2) {
        let localList = await this.$messageDb.getItem('grouplist')
        localList.forEach(l => {
          if (l.id == obj.id) {
            l.unRead = 0
          }
        })
        await this.$messageDb.setItem('grouplist', localList)
      }
      //调用消息初始化
      this.$nextTick(() => {
        this.$refs.messagePlant.initdata()
      })
    },
    openMenu(e, item) {
      e.preventDefault()
      let that = this
      this.right = false
      this.right = true
      this.acitonId = item.id
      this.acitonItems = item
      this.userNote = item.noteName
      this.userNoteId = item.noteNameId
      setTimeout(() => {
        if (e.clientY > 495) {
          that.$refs.rightClick.style.top = -120 + e.clientY + 'px'
        } else {
          that.$refs.rightClick.style.top = 10 + e.clientY + 'px'
        }
      }, 0)
    },
    down(event) {
      this.flag = true
      this.downY = event.clientY // 获取到点击的x下标
      this.scrollTop = document.getElementById('listplant').scrollTop
    },
    move(event) {
      if (this.flag) {
        var moveY = event.clientY // 获取移动的x轴
        var scrollY = moveY - this.downY // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
        document.getElementById('listplant').scrollTop = this.scrollTop - scrollY
      }
    },
    up(e) {
      this.flag = false
    },
    leave() {
      this.flag = false
    },

    async messageTon(item) {
      let that = this
      console.log('通知弹窗')
      console.log(item)
      //判断消息类型
      if (item.chatType == 'groupChat') {
        //群组消息
        item.nickname = item.groupname
      }

      //消息铃声
      let playmu = true
      //消息详情
      let playinfo = true
      //消息弹窗
      let playdialog = true
      if (storage.has(storage.get('ImAcount') + 'seting')) {
        let obj = JSON.parse(storage.get(storage.get('ImAcount') + 'seting'))
        console.log(obj)
        playmu = obj.newMessageNotice
        playinfo = obj.noticeShowDetail
        playdialog = obj.messagePopup
      }
      console.log('播放铃声?', playmu)
      if (playmu) {
        console.log('播放铃声')
        const { isOpenPlayRing, playRing } = usePlayRing()
        if (isOpenPlayRing.value) playRing()
      }
      if (!playdialog) {
        return
      }
      let left = parseInt((window.innerWidth - 570) / 2)
      //增加消息提示
      let headericon = this.$createElement('img', {
        attrs: {
          src: item.avatarurl,
        },
        style: {
          width: '48px',
          height: '48px',
          borderRadius: '50%',
          marginLeft: '-14px',
        },
      })
      if (item.chatType == 'groupChat') {
        headericon = this.$createElement('img', {
          attrs: {
            src: '@/assets/groupTheme.png',
          },
          style: {
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            marginLeft: '-14px',
          },
        })
        console.log(headericon)
      }
      let newnum = this.$createElement('span', { style: { backgroundColor: '#ED2616', textAlign: 'center', lineHeight: '16px', marginLeft: '10px', width: '28px', height: '16px', borderRadius: '15px', color: '#fff', display: 'block', marginTop: '3px', fontSize: '12px' } }, item.unread_num)
      if (item.unread_num == 0) {
        newnum = this.$createElement('span')
      }
      let content = this.$createElement('span', { style: { height: '16px' } }, item.msg)
      if (!playinfo) {
        content = '详情'
      }
      let msg = this.$createElement('div', { style: { display: 'flex' } }, [content, newnum])
      notification.open({
        message: item.nickname,
        description: msg,
        onClick: function () {
          console.log('9090909090跳转会话', item)
          if (item.chatType == 'groupChat') {
            let oldid = that.$route.params.id
            that.$router.push({ name: 'Crowd', params: { id: 2, ir: true, itemId: item.id, chatType: 'groupChat' } })
            let newid = that.$route.params.id
            if (oldid == newid) {
              that.itemId = item.id
              that.chatType = 'groupChat'
              that.listItem.forEach(async itemf => {
                if (item.id == itemf.id) {
                  if (itemf.unread_num > 0) {
                    await MessageUtils.sendMessageGoBack(itemf.id, that.chatType, this)
                    itemf.unread_num = 0
                  }
                  that.$forceUpdate()
                }
              })
            }
            that.$emit('setShowItem', { type: 2, show: false })
          } else {
            let oldid = that.$route.params.id
            that.$router.push({ name: 'Main', params: { id: 1, ir: true, itemId: item.id, chatType: 'singleChat' } })
            let newid = that.$route.params.id
            if (oldid == newid) {
              that.itemId = item.id
              that.chatType = 'singleChat'
              that.listItem.forEach(async itemf => {
                if (item.id == itemf.id) {
                  if (itemf.unread_num > 0) {
                    await MessageUtils.sendMessageGoBack(itemf.id, that.chatType, this)
                    itemf.unread_num = 0
                  }
                  that.$forceUpdate()
                }
              })
            }

            that.$emit('setShowItem', { type: 1, show: false })
          }
          notification.close(item.id)
        },
        key: item.id,
        style: {
          width: '570px',
          marginLeft: left + 'px',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        },
        icon: headericon,
      })
    },
    popNotice(headericon, areaInfo, message) {
      let that = this
      console.log(message)
      this.inter = setInterval(function () {
        var title = document.title
        if (isShine == true) {
          if (/新/.test(title) == false) {
            document.title = '【你有新消息】'
          } else {
            document.title = '【　　　　　】'
          }
        } else {
          clearInterval(that.inter)
          document.title = titleInit
        }
      }, 500)
      // 判断是否处于最小化状态
      console.log(document.visibilityState)
      if (document.visibilityState === 'hidden') {
        if (window.Notification) {
          // 判断是否支持提醒
          //判断是否允许提醒
          if (Notification.permission === 'granted') {
            // 允许的话弹出提醒
            this.pop(headericon, areaInfo, name)
          } else if (Notification.permission !== 'denied') {
            // 不允许的话重新询问浏览器是否可以接收提醒
            Notification.requestPermission(function (permission) {
              this.pop(headericon, areaInfo, name)
            })
          }
        } else {
          alert('你的浏览器不支持此消息提示功能，请使用chrome内核的浏览器！')
        }
      }
    },
    pop(headericon, areaInfo, name) {
      console.log(areaInfo)
      const notification1 = new Notification('新消息：', {
        body: areaInfo,
        // icon: headericon
      })
      notification1.onshow = () => {
        console.log('通知显示了')
      }
      notification1.onclose = () => {
        console.log('通知被关闭了')
      }
      notification1.onerror = () => {
        console.log('遇到错误')
      }
    },
    async messageTonGroup(item) {
      //消息铃声
      let playmu = true
      //消息详情
      let playinfo = true
      //消息弹窗
      let playdialog = true
      console.log(item)

      if (storage.has(storage.get('ImAcount') + 'seting')) {
        let obj = JSON.parse(storage.get(storage.get('ImAcount') + 'seting'))
        playmu = obj.newMessageNotice
        playinfo = obj.noticeShowDetail
        playdialog = obj.messagePopup
      }
      console.log('播放铃声1?', playmu)
      if (playmu) {
        const { isOpenPlayRing, playRing } = usePlayRing()
        if (isOpenPlayRing.value) playRing()
      }
      if (!playdialog) {
        return
      }
      let left = parseInt((window.innerWidth - 570) / 2)
      //增加消息提示
      let headericon = this.$createElement('img', {
        attrs: {
          src: item.avatarurl,
        },
        style: {
          width: '48px',
          height: '48px',
          borderRadius: '50%',
          marginLeft: '-14px',
        },
      })

      let newnum = this.$createElement('span')
      let content = this.$createElement('span', {}, item.content)
      if (!playinfo) {
        content = item.nickname
      }
      let msg = this.$createElement('div', { style: { display: 'flex' } }, [content, newnum])
      notification.open({
        message: item.nickname,
        description: msg,
        style: {
          width: '570px',
          // marginLeft: `${left}px`,
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        },
        icon: headericon,
      })
    },
    async messageTonFileds(item) {
      //消息铃声
      let playmu = true
      //消息详情
      let playinfo = true
      //消息弹窗
      let playdialog = true

      if (storage.has(storage.get('ImAcount') + 'seting')) {
        let obj = JSON.parse(storage.get(storage.get('ImAcount') + 'seting'))
        playmu = obj.newMessageNotice
        playinfo = obj.noticeShowDetail
        playdialog = obj.messagePopup
      }
      console.log('播放铃声2?', playmu)
      if (playmu) {
        const { isOpenPlayRing, playRing } = usePlayRing()
        if (isOpenPlayRing.value) playRing()
      }
      if (!playdialog) {
        return
      }
      let left = parseInt((window.innerWidth - 570) / 2)
      //增加消息提示
      let headericon = this.$createElement('img', {
        attrs: {
          src: item.avatarurl,
        },
        style: {
          width: '48px',
          height: '48px',
          borderRadius: '50%',
          marginLeft: '-14px',
        },
      })

      let newnum = this.$createElement('span')
      let content = this.$createElement('span', {}, item.content)
      if (!playinfo) {
        content = item.nickname
      }
      let msg = this.$createElement('div', { style: { display: 'flex' } }, [content, newnum])
      notification.open({
        message: item.nickname,
        description: msg,
        style: {
          width: '570px',
          // marginLeft: `${left}px`,
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        },
        icon: headericon,
      })
    },
    //本地群聊更新
    async addlocalgroupold(res, e, isinster) {
      res.forEach(rl => {
        if (rl.id == e.to) {
          rl.msg = ConversationUtils.getMessageType(e.type, e.msg)
          rl.order = e.order
          if (e.fromtype == 'local') {
            rl.time = e.time
          }
        }
      })
      this.listItem.forEach(it => {
        if (it.id == e.to) {
          it.msg = ConversationUtils.getMessageType(e.type, e.msg)
          it.order = e.order
          if (e.fromtype == 'local') {
            it.time = e.time
          }
        }
      })
      await this.$messageDb.setItem('grouplist', res)
      ConversationUtils.updateTopList(this.listItem)
      this.listItem.sort((a, b) => {
        return b.order - a.order
      })
    },
    //服务器群聊更新
    async addservegroupold(res, e, isinster) {
      isinster.msg = ConversationUtils.getMessageType(e.type, e.msg)
      isinster.order = e.order
      isinster.time = e.time
      if (isinster.unread_num) {
        isinster.unread_num = isinster.unread_num + 1
      } else {
        if (e.deliver) {
          isinster.unread_num = 0
        } else {
          isinster.unread_num = 1
        }
      }
      this.listItem.forEach(it => {
        if (it.id == e.to) {
          it.msg = isinster.msg
          it.order = e.order
          it.time = e.time
          it.unread_num = isinster.unread_num
        }
      })
      if (isinster.mdtype != 'NONE') {
        this.messageTon(isinster)
      }
      await this.$messageDb.setItem('grouplist', res)
      ConversationUtils.updateTopList(this.listItem)
      console.log('群聊旧会话', this.listItem)
      this.listItem.sort((a, b) => {
        return b.order - a.order
      })
      console.log('群聊旧会话', this.listItem)
    },
    //服务器单聊添加
    async addservenew(res, e) {
      //服务器单聊新会话
      //单聊新会话
      let userinfo = ''
      let iobj = {}
      userinfo = await EaseChatClient.fetchUserInfoById(e.from)
      Object.keys(userinfo.data).forEach(item => {
        iobj = {
          id: item,
          nickname: userinfo.data[item]['nickname'],
          avatarurl: userinfo.data[item]['avatarurl'],
          msg: ConversationUtils.getMessageType(e.type, e.msg),
          time: e.time,
          unread_num: 0,
          order: e.order,
        }
        if (storage.has('header-name-bz' + item)) {
          iobj.nickname = storage.get('header-name-bz' + item)
        }
        console.log(iobj)
        iobj.msg = ConversationUtils.getMessageType(e.type, e.msg)
        iobj.unread_num = 0
        if (this.pageid == 1) {
          this.listItem.push(iobj)
        }
      })
      res.push(iobj)
      this.messageTon(iobj)
      await this.$messageDb.setItem('corlist', res)
      ConversationUtils.updateTopList(this.listItem)
      this.listItem.sort((a, b) => {
        return b.order - a.order
      })
    },
    //服务器单聊更新
    async addserveold(res, e, isinster) {
      //单聊旧会话
      console.log('单聊旧会话', isinster.mdtype)

      console.log('单聊旧会话', e)

      isinster.msg = ConversationUtils.getMessageType(e.type, e.msg)
      isinster.order = e.order
      isinster.time = e.time
      if (isinster.unread_num) {
        isinster.unread_num = isinster.unread_num + 1
      } else {
        if (e.deliver) {
          isinster.unread_num = 0
        } else {
          isinster.unread_num = 1
        }
      }
      this.listItem.forEach(it => {
        if (it.id == e.from) {
          it.msg = isinster.msg
          it.order = e.order
          it.unread_num = isinster.unread_num
          it.time = e.time
        }
      })
      res.forEach(rl => {
        if (rl.id == e.from) {
          console.log('找到本地单聊')
          rl.msg = isinster.msg
          rl.order = isinster.order
          rl.time = isinster.time
          console.log('找到本地单聊', rl)
        }
      })
      console.log(isinster)
      if (isinster.mdtype != 'NONE') {
        this.messageTon(isinster)
      }
      console.log(res)
      console.log(this.listItem)
      await this.$messageDb.setItem('corlist', res)
      ConversationUtils.updateTopList(this.listItem)
      this.listItem.sort((a, b) => {
        return b.order - a.order
      })
    },
    //本地单聊添加
    async addlocalnew(res, e) {
      //添加新会话  单聊
      let userinfo = ''
      let iobj = {}
      userinfo = await EaseChatClient.fetchUserInfoById(e.to)
      Object.keys(userinfo.data).forEach(item => {
        iobj = {
          id: item,
          nickname: userinfo.data[item]['nickname'],
          avatarurl: userinfo.data[item]['avatarurl'],
          msg: ConversationUtils.getMessageType(e.type, e.msg),
          time: e.time,
          unread_num: 0,
          order: e.order,
        }
        if (storage.has('header-name-bz' + item)) {
          iobj.nickname = storage.get('header-name-bz' + item)
        }
        console.log(iobj)
        iobj.msg = ConversationUtils.getMessageType(e.type, e.msg)
        iobj.order = e.order
        iobj.time = e.time
        iobj.unread_num = 0
        if (this.pageid == 1) {
          this.listItem.push(iobj)
        }
      })
      res.push(iobj)
      await this.$messageDb.setItem('corlist', res)
    },
    //本地单聊更新
    async addlocalold(res, e, isinster) {
      //更新旧会话 单聊
      console.log('本地旧会话')
      //更新本地旧会话
      isinster.msg = ConversationUtils.getMessageType(e.type, e.msg)
      isinster.order = e.order
      isinster.time = e.time
      this.listItem.forEach(it => {
        if (it.id == e.to) {
          it.msg = isinster.msg
          it.order = e.order
          it.unread_num = isinster.unread_num
          it.time = e.time
        }
      })
      await this.$messageDb.setItem('corlist', res)
    },
    async updateList(e) {
      //收到消息判断 单聊 群聊 好友
      if (e.chatType == 'singleChat') {
        console.log('单聊')
        //消息来源 本地
        if (e.fromtype == 'local') {
          console.log('本地单聊')
          let isinster = null
          let res = await this.$messageDb.getItem('corlist')
          res.forEach(rl => {
            if (rl.id == e.to) {
              isinster = rl
            }
          })
          if (isinster == null) {
            //新会话
            this.addlocalnew(res, e)
          } else {
            //旧会话
            this.addlocalold(res, e, isinster)
          }
        } else {
          console.log('服务器单聊')
          let isinster = null
          let res = await this.$messageDb.getItem('corlist')
          res.forEach(rl => {
            if (rl.id == e.from) {
              isinster = rl
            }
          })
          if (isinster == null) {
            //新会话
            this.addservenew(res, e)
          } else {
            //旧会话
            this.addserveold(res, e, isinster)
          }
        }
      } else if (e.chatType == 'groupChat') {
        console.log('群聊')

        if (e.fromtype == 'local') {
          let isinster = null
          let res = await this.$messageDb.getItem('grouplist')
          res.forEach(rl => {
            if (rl.id == e.to) {
              isinster = rl
            }
          })
          if (isinster != null) {
            //旧会话
            this.addlocalgroupold(res, e, isinster)
          }
        } else {
          console.log('服务器群聊')
          let isinster = null
          let res = await this.$messageDb.getItem('grouplist')
          res.forEach(rl => {
            if (rl.id == e.to) {
              isinster = rl
            }
          })
          if (isinster != null) {
            //旧会话
            this.addservegroupold(res, e, isinster)
          }
        }
      }
    },
    async menuHandle() {
      console.log('列表为', this.pId)

      //初始化消息监听
      console.log('监听消息总线')
      this.$bus.$off('im.all')
      this.$bus.$on('im.all', this.eventAll)

      //监听会话消息变更
      this.$bus.$off('im.allSessionNewMessage')
      this.$bus.$on('im.allSessionNewMessage', this.fushSession)

      //监听加入群组
      this.$bus.$off('im.allJionGroup')
      this.$bus.$on('im.allJionGroup', this.jionGroup)
      //监听离开群组
      this.$bus.$off('im.allLeaveGroup')
      this.$bus.$on('im.allLeaveGroup', this.leaveGroup)
      //监听解散群组
      this.$bus.$off('im.allDeleteGroup')
      this.$bus.$on('im.allDeleteGroup', this.deleteGroup)
      //监听更新群组
      this.$bus.$off('im.allUpdateGroup')
      this.$bus.$on('im.allUpdateGroup', this.updateGroup)
      //显示会话
      this.$bus.$off('im.allShowChat')
      this.$bus.$on('im.allShowChat', this.showChat)

      //监听新会话
      this.$bus.$off('im.allSessionNewSession')
      this.$bus.$on('im.allSessionNewSession', this.newSession)

      //设置路由类型 单聊
      if (this.id == 1) {
        this.type = 'chat'
      }
      //设置路由类型 群聊
      if (this.id == 2) {
        this.type = 'group'
      }
      //初始化会话列表为空
      this.pageid = this.pId
      this.itemId = '0'
      this.userId = '0'
      this.listItem = []
      if (this.pageid == 1) {
        console.log('获取单聊会话列表')
        this.listItem = await this.$ImSdk.getlocalChat(this)
        this.listItem.forEach(e=>{
          if(e.otherMember == null){
            console.log('此用户不存在',e)
          }
        })
      }
      if (this.pageid == 2) {
        console.log('获取群聊会话列表')
        this.listItem = await this.$ImSdk.getlocalGroup(this)
      }
      this.listItem.forEach(e => {
        if (e.id == this.itemId) {
          this.setItem(e)
        }
      })
    },
    async newSession(e) {
      console.log('收到新会话产生消息', e)
      console.log('当前会话', this.itemId)
      console.log('当前分组', this.pageId)
      if (e.message) {
        e.message.createTime = new Date(e.message.createTime).getTime()
        if (e.message.messageFrom != storage.get('userId')) {
          this.noteMessage(e.otherMember.avatar, this.getMessageType(e.message), e.otherMember.nickname, e)
        }
      }

      //当前是否是单聊会话
      if (this.pageid == 1) {
        console.log('进入单聊新会话')
        if (this.listItem.length > 0) {
          this.listItem.unshift(e)
        } else {
          this.listItem.push(e)
        }
      }
      this.listItem = this.$ImSdk.updateTopSort(this.listItem)
      //更新本地会话数据
      let res = await this.$messageDb.getItem('corlist')
      res.push(e)
      res = this.$ImSdk.updateTopSort(res)
      await this.$messageDb.setItem('corlist', res)
      this.$forceUpdate()
    },
    async showChat(e) {
      console.log('收到会话显示事件', e)
      this.listItem.forEach(item => {
        if (item.id == e.messageSessionId) {
          item.isShow = true
        }
      })
      this.listItem = this.$ImSdk.updateTopSort(this.listItem)
      let loaclDb = 'corlist'
      if (e.messageSessionType == 'chat') {
        loaclDb = 'corlist'
      } else {
        loaclDb = 'grouplist'
      }
      let res = await this.$messageDb.getItem(loaclDb)
      res.forEach(rl => {
        if (rl.id == e.messageSessionId) {
          rl.isShow = true
        }
      })
      res = this.$ImSdk.updateTopSort(res)
      await this.$messageDb.setItem(loaclDb, res)
      this.$forceUpdate()
      console.log('会话显示事件设置完毕', e)
      // //通知会话列表
      this.fushSession(e)
    },
    async fushSession(e) {
      console.log('收到会话最后一条消息变更', e)
      console.log('当前会话', this.itemId)
      let info = this.$ImSdk.getMessageMembersInfo(e)
      if (e.messageFrom != storage.get('userId')) {
        let ismd = 'corlist'
        if (e.messageSessionType == 'chat') {
          ismd = 'corlist'
        } else {
          ismd = 'grouplist'
        }
        let resmd = await this.$messageDb.getItem(ismd)
        let md = false
        resmd.forEach(item => {
          if (item.id == e.messageSessionId) {
            md = item.isDisturb
          }
        })
        if (!md) {
          this.noteMessage(info.avatar, this.getMessageType(e), info.nickname, e)
        }
      }
      //更新当前会话最后一条消息
      this.listItem.forEach(item => {
        if (item.id == e.messageSessionId) {
          e.createTime = new Date(e.createTime).getTime()
          item.message = e
          //设置未读 条件： 是否在当前会话 如果是当前会话不设置 如果不是设置未读加1
          if (this.itemId != e.messageSessionId) {
            item.unRead = item.unRead + 1
          }
        }
      })
      this.listItem = this.$ImSdk.updateTopSort(this.listItem)
      let loaclDb = 'corlist'
      if (e.messageSessionType == 'chat') {
        loaclDb = 'corlist'
      } else {
        loaclDb = 'grouplist'
      }
      //更新本地会话数据
      let res = await this.$messageDb.getItem(loaclDb)
      res.forEach(rl => {
        if (rl.id == e.messageSessionId) {
          e.createTime = new Date(e.createTime).getTime()
          rl.message = e
          //设置未读 条件： 是否在当前会话 如果是当前会话不设置 如果不是设置未读加1
          if (this.itemId != e.messageSessionId) {
            rl.unRead = rl.unRead + 1
          }
        }
      })
      res = this.$ImSdk.updateTopSort(res)
      await this.$messageDb.setItem(loaclDb, res)
      this.$forceUpdate()
    },
    getMessageType(e) {
      if (e.messageType == 'txt') {
        return e.messageMsg
      }
      if (e.messageType == 'audio') {
        return e.messageMsg
      }
      if (e.messageType == 'video') {
        return e.messageMsg
      }
      if (e.messageType == 'custom') {
        return e.messageMsg
      }
      if (e.messageType == 'img') {
        return e.messageMsg
      }
      return '未知消息'
    },

    menuClick(e) {},
    setAdd() {
      this.add = !this.add
    },
    closeMb() {
      this.add = false
      this.right = false
    },

    eventAll(e, vuedom) {
      console.log('总线收到消息')
      console.log(e)
      switch (e.code) {
        case 50:
          //单聊
          //通知消息列表
          vuedom.$bus.$emit('im.allChatMessage', JSON.parse(e.content))
          //通知会话列表
          vuedom.$bus.$emit('im.allSessionNewMessage', JSON.parse(e.content))
          break
        case 51:
          //群聊
          //通知消息列表
          vuedom.$bus.$emit('im.allGroupMessage', JSON.parse(e.content))
          //通知会话列表
          vuedom.$bus.$emit('im.allSessionNewMessage', JSON.parse(e.content))
          break
        case 60:
          //新会话创建
          vuedom.$bus.$emit('im.allSessionNewSession', JSON.parse(e.content))
          break
        case 70:
          //群组创建成功
          break
        case 71:
          //群组创建失败
          break
        case 72:
          //加入群组成功
          vuedom.$bus.$emit('im.allJionGroup', JSON.parse(e.content))
          break
        case 73:
          //更新群组
          vuedom.$bus.$emit('im.allUpdateGroup', JSON.parse(e.content))
          break
        case 74:
          //解散群组事件
          vuedom.$bus.$emit('im.allDeleteGroup', JSON.parse(e.content))
          break
        case 75:
          //离开群组事件
          vuedom.$bus.$emit('im.allLeaveGroup', JSON.parse(e.content))
          break
        case 80:
          //离线消息
          break
        case 100:
          //服务器接到消息回执
          //通知消息列表
          vuedom.$bus.$emit('im.allChatMessage', JSON.parse(e.content))
          //通知会话列表
          vuedom.$bus.$emit('im.allSessionNewMessage', JSON.parse(e.content))
          break
        case 1000:
          //已读消息回执
          vuedom.$bus.$emit('im.allChatReadMessage', JSON.parse(e.content))
          break
        case 1001:
          //显示会话
          vuedom.$bus.$emit('im.allShowChat', JSON.parse(e.content))
          break
        case 1003:
          //撤回消息通知
          vuedom.$bus.$emit('im.allBackMessage', JSON.parse(e.content))
          break
        default:
          break
      }
      //100 消息发送成功

      //101 消息发送失败

      //50 收到单聊
      //51 收到群聊消息

      //60 收到新会话

      //70 创建群组成功消息
      //71 创建群组失败消息

      //72 加入群组成功
      //73 加入群组失败

      //80 离线消息

      //1000 消息阅读回执

      //
      // /* message 相关监听 */
      // let that = this
      // // EaseChatClient.addEventHandler('handlerId', {
      // //   onTextMessage: function (message) {
      // //     console.log('>>>>>>>App mesage多端', message)
      // //     // console.log('setMessageKey', setMessageKey(message))
      // //     // that.pushNewMessage(message)
      // //   },
      // // })
      // console.log("初始化监听")
      // EaseChatClient.addEventHandler('handlerId', {
      //   onConnected: () => {
      //     console.log("onConnected");
      //   },
      //   onDisconnected: () => {
      //     console.log("onDisconnected");
      //   },
      //   onTokenWillExpire: () => {
      //     console.log("onTokenWillExpire");
      //   },
      //   onTokenExpired: () => {
      //     console.log("onTokenExpired");
      //   },
      //   onTextMessage: function (message) {
      //     console.log('>>>>>>>App mesage', message)
      //     // console.log('setMessageKey', setMessageKey(message))
      //     that.pushNewMessage(message)
      //   },    // 收到文本消息。
      //   onEmojiMessage: function (message) {
      //     that.pushNewMessage(message)
      //   },   // 收到表情消息。
      //   onImageMessage: function (message) {
      //     that.pushNewMessage(message)
      //   },   // 收到图片消息。
      //   onCmdMessage: function (message) {
      //     console.log('>>>>>收到命令消息', message)
      //   },     // 收到命令消息。
      //   onAudioMessage: function (message) {
      //     that.pushNewMessage(message)
      //   },   // 收到音频消息。
      //   onLocationMessage: function (message) {
      //     that.pushNewMessage(message)
      //   },// 收到位置消息。
      //   onFileMessage: function (message) {
      //     that.pushNewMessage(message)
      //   },    // 收到文件消息。
      //   onCustomMessage: function (message) {
      //     that.pushNewMessage(message)
      //   },  // 收到自定义消息。
      //   onVideoMessage: function (message) {
      //     that.pushNewMessage(message)
      //   },     // 收到视频消息。
      //   onRecallMessage: function (message) {
      //     console.log('回撤消息', message)
      //     // otherRecallMessage(message)
      //   },    // 收到消息撤回回执。
      //   onReadMessage: function (message) {
      //     console.log("收到消息已读回执", message)
      //     that.pushReadMessage(message)
      //   },
      //   onChannelMessage: function (message) {
      //     that.pushChannelMessage(message)
      //   },
      //   onReceivedMessage: function (message) {
      //     that.pushReceivedMessage(message)
      //   },
      //   onDeliveredMessage(message) {
      //     that.pushDeliverMessage(message)
      //   }
      // })

      // EaseChatClient.addEventHandler('friendListen', {
      //   // 收到好友邀请触发此方法。
      //   onContactInvited: (data) => {
      //     console.log('>>>>>>收到好友申请', data)
      //     that.pushMNewFileds(data)
      //   },
      //   // 联系人被删除时触发此方法。
      //   onContactDeleted: (data) => {
      //     console.log('>>>>收到好友关系解散', data)
      //     that.pushMRestFileds(data)
      //   },
      //   // 新增联系人会触发此方法。
      //   onContactAdded: (data) => {
      //     console.log('>>>>好友新增监听', data)
      //     that.pushMRestFileds(data)
      //   },
      //   // 好友请求被拒绝时触发此方法。
      //   onContactRefuse: (data) => {
      //     console.log('>>>>>>好友申请被拒绝', data)
      //   },
      //   // 好友请求被同意时触发此方法。
      //   onContactAgreed: (data) => {
      //     console.log('>>>>>对方同意了好友申请', data)
      //     that.pushMRestFileds(data)
      //   }
      // })
      // /* 群组相关监听 */
      // EaseChatClient.addEventHandler('groupEvent', {
      //   onGroupChange: (data) => {
      //     console.log('>>>>>>>收到群组事件', data.type)
      //     if (data.type == "invite") {
      //       console.log('>>>>>>>收到群组事件', data)
      //       that.pushMNewGroups(data)
      //     }
      //     if (data.type == "joinGroupNotifications") {
      //       console.log('>>>>>>>收到群组事件', data)
      //       that.pushMNewGroups(data)
      //     }
      //     if (data.type == "direct_joined") {
      //       console.log('>>>>>>>收到群组事件', data)
      //       that.pushMNewGroupsJoin(data)
      //     }
      //     if (data.type == "removedFromGroup") {
      //       console.log('>>>>>>>收到群组事件', data)
      //       that.pushMNewGroupsRemove(data)
      //     }
      //     if (data.type == "leaveGroup") {
      //       console.log('>>>>>>>收到群组事件', data)
      //       that.pushMNewGroupsleave(data)
      //     }
      //     if (data.type == "memberJoinPublicGroupSuccess") {
      //       console.log('>>>>>>>收到群组事件', data)
      //       that.pushMNewGroupsmemberJoinSuccess(data)
      //     }
      //     if (data.type == "joinPublicGroupSuccess") {
      //       console.log('>>>>>>>收到群组事件', data)
      //       that.pushMNewGroupsjoinPublicGroupSuccess(data)
      //     }
      //     if (data.type == "joinPublicGroupDeclined") {
      //       console.log('>>>>>>>收到群组事件', data)
      //       that.pushMNewGroupsjoinPublicGroupDeclined(data)
      //     }
      //     if (data.type == "deleteGroupChat") {
      //       console.log('>>>>>>>收到群组事件', data)
      //       that.pushMNewGroupsdeleteGroupChat(data)
      //     }

      //   }
      // })
    },
    async pushNewMessage(message) {
      console.log('分发收到消息：', message)
      this.popNotice(1, '新消息通知', message)
      await MessageUtils.CreateServiceMessage(message)
      this.$bus.$emit('newMessage', message)
      this.$bus.$emit('newMessageHost', message)
    },
    pushMNewFileds(message) {
      this.$bus.$emit('addFileds', message)
    },
    pushMNewGroups(message) {
      this.$bus.$emit('addGroups', message)
    },
    pushMNewGroupsJoin(message) {
      this.$bus.$emit('joinGroups', message)
    },
    pushMNewGroupsRemove(message) {
      this.$bus.$emit('RemoveGroups', message)
    },
    pushMNewGroupsleave(message) {
      this.$bus.$emit('leaveGroups', message)
    },
    pushMNewGroupsmemberJoinSuccess(message) {
      this.$bus.$emit('pushMNewGroupsmemberJoinSuccess', message)
    },
    pushMNewGroupsjoinPublicGroupSuccess(message) {
      this.$bus.$emit('pushMNewGroupsjoinPublicGroupSuccess', message)
    },
    pushMNewGroupsjoinPublicGroupDeclined(message) {
      this.$bus.$emit('pushMNewGroupsjoinPublicGroupDeclined', message)
    },
    pushMNewGroupsdeleteGroupChat(message) {
      this.$bus.$emit('pushMNewGroupsdeleteGroupChat', message)
    },

    pushMRestFileds(message) {
      this.$bus.$emit('RestFileds', message)
    },
    pushDeliverMessage(message) {
      this.$bus.$emit('pushDeliverMessage', message)
    },
    pushReceivedMessage(message) {
      this.$bus.$emit('pushReceivedMessage', message)
    },
    pushChannelMessage(message) {
      this.$bus.$emit('pushChannelMessage', message)
    },
    pushReadMessage(message) {
      this.$bus.$emit('pushReadMessage', message)
    },
  },
}
</script>
<style lang="less" scoped>
.mtop20 {
  margin-top: 18px !important;
}

.item-mdr {
  width: 18px;
  height: 18px;
}

.item-mdr-zd {
  width: 48px;
  height: 18px;
  color: #ed2616;
  font-size: 12px;
  right: 20px;
  top: 3px;
  position: absolute;
}

.item-mdr img {
  width: 18px;
  height: 18px;
}

.time {
  font-size: 12px;
  color: #999;
}

.new-dot {
  width: 9px;
  height: 9px;
  opacity: 1;
  background: #ed2616;
  position: absolute;
  top: 2px;
  right: 0px;
  border-radius: 50%;
}

.f-splt {
  width: 85px;
  height: 1px;
  opacity: 1;
  background: #eeeeee;
  margin-top: 4px;
  margin-bottom: 3px;
}

.list-plant {
  // height: 566px;
  height: calc(100% - 74px);
  overflow-x: scroll;
}

.right-click {
  position: absolute;
  width: 85px;
  height: 153px;
  border-radius: 4px;
  opacity: 1;
  background: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  top: -500px;
  left: 350px;
  font-size: 12px;
  line-height: 28px;
  padding-top: 1px;
  cursor: pointer;
}

.right-click-fileds {
  height: 28px;
}

.list-plant::-webkit-scrollbar {
  display: none;
}

.item-body {
  margin-left: 10px;
}

.list-item {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  cursor: pointer;
}

#app-menu {
  height: 100%;
  text-align: center;

  .layout-sider {
    background-color: #fafafa;
    width: 290px !important;
    flex: 0 0 290px !important;
    max-width: 290px !important;
    min-width: 290px !important;
    width: 290px;
    height: 100%;
    overflow: hidden;
  }
}

.mb {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
}

.list {
  width: 290px;
  height: 100%;
  background-color: #f7f7f7;
}

.action-item {
  margin-top: 26px;
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.login-input {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  width: 220px;
  height: 29px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 29px;
  outline: none;
  color: #999;
}

.login-input:focus {
  outline: none;
  border: 1px solid #d8d8d8;
}

.login-input::placeholder {
  color: #999999;
}

.seachgroupuser-input {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  background: #eeeeee;
  width: 164px;
  height: 29px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 29px;
  outline: none;
  color: #999;
}

.seachgroupuser-input:focus {
  outline: none;
  border: 1px solid #d8d8d8;
}

.seachgroupuser-input::placeholder {
  color: #999999;
}

.seach-button {
  background-image: url('~@/assets/seach.png');
  background-size: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 195px;
  cursor: pointer;
}

.seach-button-group {
  background-image: url('~@/assets/seach.png');
  background-size: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 140px;
  cursor: pointer;
}

.action-edit {
  background-image: url('~@/assets/edit1.png');
  background-size: 100%;
  width: 29px;
  height: 29px;
  cursor: pointer;
  position: relative;
}

.action-edit-avicat {
  background-image: url('~@/assets/edit2.png');
  background-size: 100%;
  width: 29px;
  height: 29px;
  cursor: pointer;
  position: relative;
}

.add-plant {
  width: 70px;
  height: 54px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 60px;
  color: #222;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  padding: 8px;
  z-index: 1000;
  left: 300px;
}

.p-text:hover {
  color: #ed2616;
}

.list-item {
  width: 290px;
  height: 70px;
}

.list-item:hover {
  width: 290px;
  height: 70px;
  background: linear-gradient(90deg, rgba(223, 79, 75, 0) 0%, rgba(223, 79, 75, 0.1) 100%);
  border-right: 2px solid #ed2616;
}

.list-item-active {
  width: 290px;
  height: 70px;
  background: rgba(237, 38, 22, 0.08);
  border-right: none;
}

.list-splint {
  width: 261px;
  height: 1px;
  opacity: 1;
  border: 1px solid;
  border-image: linear-gradient(180deg, #dddddd 0%, rgba(238, 238, 238, 0) 99%);
}

.item-header {
  width: 40px;
  height: 40px;
  position: relative;
  margin-top: 5px;
}

.item-header-ruand {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  background-color: #ed2616;
  margin-top: 5px;
}

.item-header-ruand img {
  width: 20px;
  height: 20px;
  margin-top: 10px;
}

.item-header-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.item-dot {
  transform: scale(0.8);
  height: 16px;
  line-height: 16px;
  border-radius: 7.5px;
  opacity: 1;
  background: #ed2616;
  color: #fff;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
}

.item-nickname {
  font-family: PingFangSC-Medium;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  width: 200px;
  text-align: left;
  letter-spacing: 0px;
  color: #222222;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.groupname {
  max-width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.item-dec-text {
  font-family: PingFangSC-Regular;
  font-size: 11px;
  font-weight: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999999;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 170px;
  text-align: left;
  height: 15px;
}

.item-dec {
  display: flex;
  justify-content: space-between;
  width: 205px;
  margin-top: 5px;
  position: relative;
}

.mtp200 {
  margin-top: 200px;
}

/deep/ .ant-modal-header {
  text-align: center;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
}

/deep/ .ant-modal-body {
  padding: 16px;
}

/deep/ .ant-modal-title {
  font-size: 14px;
}

/deep/ .ant-btn-primary {
  color: #fff;
  background-color: #ed2616 !important;
  border-color: #ed2616 !important;
}

.ftitle {
  height: 16px;
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ed2616;
  line-height: 16px;
}

.login-input-f {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #ed2616;
  width: 212px;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  color: #222;
  margin-top: 16px;
}

.login-input-f:focus {
  outline: none;
  border: 1px solid #ed2616;
}

.login-input-f::placeholder {
  color: #d8d8d8;
}

.login-input-f-ix {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #ed2616;
  width: 358px;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  color: #222;
  margin-top: 16px;
}

.login-input-f-ix:focus {
  outline: none;
  border: 1px solid #ed2616;
}

.login-input-f-ix::placeholder {
  color: #d8d8d8;
}

.login-input-f-ex {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  width: 358px;
  height: 73px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  color: #222;
  margin-top: 16px;
}

.login-input-f-ex:focus {
  outline: none;
  border: 1px solid #cccccc;
}

.login-input-f-ex::placeholder {
  color: #d8d8d8;
}

.login-button-queren {
  width: 80px;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(90deg, #fe5d42 0%, #ed2616 100%);
  border: none;
  color: #fff;
  margin-top: 21px;
  cursor: pointer;
}

.login-button-quxiao {
  width: 80px;
  height: 32px;
  border-radius: 4px;
  color: #fff;
  background: linear-gradient(90deg, #ccc 0%, #ccc 100%);
  border: none;
  margin-top: 21px;
  cursor: pointer;
}

/deep/ .ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ed2616;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}

/deep/ .ant-radio-inner::after {
  background-color: #ed2616;
}

/deep/ .ant-radio-checked .ant-radio-inner {
  border: 1px solid #ed2616;
}

/deep/ .ant-radio-input:hover {
  border: 1px solid #ed2616;
}

/deep/ .ant-radio-inner:hover {
  border: 1px solid #ed2616;
}

.grouptitle {
  display: flex;
  justify-content: space-between;
}

.groupback {
  width: 18px;
  height: 18px;
}

.groupclose {
  width: 18px;
  height: 18px;
}

.groupline {
  width: 100%;
  height: 1px;
  border: 1px solid;
  border-image: linear-gradient(180deg, rgba(238, 238, 238, 1), rgba(238, 238, 238, 0)) 1 1;
  margin-top: 20px;
}

.seachGroupUser {
  position: relative;
}

.group-left {
  width: 195px;
  border-right: 1px solid #dddddd;
  height: 224px;
}

.group-right {
  margin-left: 16px;
}

/deep/ .ant-collapse > .ant-collapse-item {
  border: none;
  margin-top: 5px;
}

/deep/ .ant-collapse {
  border: none;
  background-color: #fff;
  font-size: 12px;
}

/deep/ .ant-collapse-content {
  border: none;
}

/deep/ .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.group-user-list {
  margin-top: 5px;
  height: 160px;
  overflow: hidden;
  overflow-y: auto;
}

.group-user-list::-webkit-scrollbar {
  display: none;
}

/deep/ .ant-collapse-content-box {
  padding: 0px;
}

.useUser {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  width: 144px;
}

.useUser-name {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}

.useUser-icon {
  width: 20px;
  height: 20px;
}

/deep/ .ant-checkbox-wrapper {
  font-size: 12px;
}

.header-items {
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  background: #f1f1f1;
}
.message-plant {
  width: 100%;
}
</style>
