<template>
  <div @click.stop="setForwardItem()">
    <!-- 撤回消息 -->
    <BackMessage v-if="getMessageTypeStr(item) == 'backMessage'" @setValues="setValues" :message="item"></BackMessage>
    <div class="other" v-if="!item.belong && item.status == 0">
      <div class="message-body">
        <!-- 选择框 -->
        <img v-if="isMore && item.messageType != 'audio'" class="forward-session-icon" style="margin-top: 8px; margin-right: 16px" :src="item.isSelect ? 'https://cdn.binliyoujia.com/static/message/forward-checked-pc.png' : 'https://cdn.binliyoujia.com/static/message/forward-check-pc.png'" />
        <!-- 头像 -->
        <div class="user-header">
          <img :src="item.otherInfo.avatar" />
        </div>
        <!-- 消息 -->
        <div class="text-plant">
          <div class="text-name">{{ getOtherName(item) }}</div>
          <!-- 文本消息 -->
          <TxtMessage v-if="getMessageTypeStr(item) == 'txtMessage'" :message="item" @openMenu="openMenu" @openForwardMessageListDailogByList="openForwardMessageListDailogByList" @goToQuoteMessage="goToQuoteMessage"></TxtMessage>
          <!-- 图片消息 -->
          <ImgMessage  v-if="getMessageTypeStr(item) == 'imgMessage'" :message="item" @openMenu="openMenu"></ImgMessage>
          <!-- 文件消息 -->
          <FileMessage  v-if="getMessageTypeStr(item) == 'fileMessage'" :message="item" @openMenu="openMenu"></FileMessage>
          <!-- 音频消息 -->
          <AudioMessage v-if="getMessageTypeStr(item) == 'audioMessage'" :message="item" @openMenu="openMenu"></AudioMessage>
          <!-- 视频消息 -->
          <VideoMessage v-if="getMessageTypeStr(item) == 'videoMessage'" :message="item" @openMenu="openMenu"></VideoMessage>
          <!-- 自定义消息 -->
          <CustomMessage v-if="getMessageTypeStr(item) == 'customMessage'" :message="item" @openMenu="openMenu" @openDemand="openDemand"></CustomMessage>
        </div>
        <!-- 消息回执 -->
        <div v-if="item.messageSessionType == 'chat'" class="red2"></div>
      </div>
      <div class="message-time" style="clear: both; padding-top: 6px">
        {{ dateFormater(item.createTime) }}
      </div>
    </div>
    <div class="other" v-if="item.belong && item.status == 0" :style="isMore && item.messageType != 'audio' ? 'display: flex;justify-content: space-between;' : 'display: flex;justify-content: space-between;'">
      <img v-if="isMore && item.messageType != 'audio'" class="forward-session-icon" style="margin-top: 8px" :src="item.isSelect ? 'https://cdn.binliyoujia.com/static/message/forward-checked-pc.png' : 'https://cdn.binliyoujia.com/static/message/forward-check-pc.png'" />
      <div :style="isMore?'flex-shrink: 0; width:calc(100% - 34px);':'flex-shrink: 0; width:calc(100%);'">
        <div class="message-body-my">
          <!-- 消息回执 -->
          <div v-if="item.messageSessionType == 'chat'" :class="item.isRead ? 'red2' : 'red'"></div>
          <!-- 消息 -->
          <div class="text-plant">
            <div class="text-name-my">{{ item.selfInfo.nickname }}</div>
            <!-- 文本消息 -->
            <TxtMessage v-if="getMessageTypeStr(item) == 'txtMessage'" :message="item"  @openMenu="openMenu" @openForwardMessageListDailogByList="openForwardMessageListDailogByList"  @goToQuoteMessage="goToQuoteMessage"></TxtMessage>
            <!-- 图片消息 -->
            <ImgMessage  v-if="getMessageTypeStr(item) == 'imgMessage'" :message="item" @openMenu="openMenu"></ImgMessage>
            <!-- 文件消息 -->
            <FileMessage  v-if="getMessageTypeStr(item) == 'fileMessage'" :message="item" @openMenu="openMenu"></FileMessage>
            <!-- 音频消息 -->
            <AudioMessage v-if="getMessageTypeStr(item) == 'audioMessage'" :message="item" @openMenu="openMenu"></AudioMessage>
            <!-- 视频消息 -->
            <VideoMessage v-if="getMessageTypeStr(item) == 'videoMessage'" :message="item" @openMenu="openMenu"></VideoMessage>
            <!-- 自定义消息 -->
            <CustomMessage v-if="getMessageTypeStr(item) == 'customMessage'" :message="item" @openMenu="openMenu" @openDemand="openDemand"></CustomMessage>
          </div>
          <!-- 头像 -->
          <div class="user-header">
            <img :src="item.selfInfo.avatar" />
          </div>
        </div>
        <div class="message-time-my" style="clear: both; padding-top: 6px">
          {{ dateFormater(item.createTime) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackMessage from '@/components/messageItem/backMessage.vue'
import TxtMessage from '@/components/messageItem/txtMessage.vue'
import ImgMessage from '@/components/messageItem/imgMessage.vue'
import FileMessage from '@/components/messageItem/fileMessage.vue'
import VideoMessage from '@/components/messageItem/videoMessage.vue'
import AudioMessage from '@/components/messageItem/audioMessage.vue'
import CustomMessage from '@/components/messageItem/customMessage.vue'
import dateFormaterSession from '@/utils/dateFormaterSession'
import fileSizeFormat from '@/utils/fileSizeFormat'
import emMap from '@/utils/em'
import previewImage from '@/components/elimage.vue'
import BenzAMRRecorder from 'benz-amr-recorder'
import KDXF from '@/Kdxf/kdxf'
export default {
  components: {
    BackMessage,
    TxtMessage,
    ImgMessage,
    FileMessage,
    VideoMessage,
    AudioMessage,
    CustomMessage,
    previewImage
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    isMore:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      fileSizeFormat: fileSizeFormat,
      armRec: null,
      audioPlayStatus: [],
    }
  },
  methods: {
    setForwardItem(){
      this.item.isSelect = !this.item.isSelect
      this.$forceUpdate()
      this.$emit('setForwardItem',this.item)
    },
    openForwardMessageListDailogByList(item){
      this.$emit("openForwardMessageListDailogByList",item)
    },
    goToQuoteMessage(item){
      this.$emit("goToQuoteMessage",item)
    },
    //判断重置消息是否显示
    getIsGoBackMessage(item) {
      let sTime = item.createTime
      let eTime = new Date().getTime()
      if (eTime - sTime > 120000) {
        return false 
      }
      return true
    },
    openMenu(e){
      console.log("右键事件第二层")
      this.$emit('openMenu',e)
    },
    openDemand(e){
      console.log("打开需求事件第二层")
      this.$emit('openDemand',e)
    },
    
    //判断消息类型
    getMessageTypeStr(item) {
      //撤回消息
      if (item.status == 2 && this.getIsGoBackMessage(item)) {
        return 'backMessage'
      }
      if(item.messageType == 'txt'){
        return 'txtMessage'
      }
      if(item.messageType == 'img'){
        return 'imgMessage'
      }
      if(item.messageType == 'file'){
        return 'fileMessage'
      } 
      if(item.messageType == 'video'){
        return 'videoMessage'
      } 
      if(item.messageType == 'audio'){
        return 'audioMessage'
      } 
      if(item.messageType == 'custom'){
        return 'customMessage'
      } 
    },
    //重新编辑
    setValues(e) {
      console.log(e)
      this.$emit('setValues',e)
    },
    //获取自定义消息标题
    getCustomButton(e) {
      if (e.messageCustomExt.type == 'designerOrder') {
        if (e.messageCustomExt.status == 100) {
          return '订单已关闭'
        }
        if (e.messageCustomExt.status == 0) {
          return '请您确认支付'
        }
        if (e.messageCustomExt.status == 10) {
          return '已支付'
        }
        if (e.messageCustomExt.status == 20) {
          return '请您确认需求'
        }
        if (e.messageCustomExt.status == 30) {
          return '制图中'
        }
        if (e.messageCustomExt.status == 40) {
          return '请您确认图纸'
        }
        if (e.messageCustomExt.status == 50) {
          return '已完成'
        }
      }
      if (e.messageCustomExt.type == 'selectOrder') {
        return '商品价格改好了，可直接付款哦'
      }
      if (e.messageCustomExt.type == 'demandOrder') {
        return '我发送了一个设计需求，请尽快查看并回复哦~'
      }
      if (e.messageCustomExt.type == 'designerFixOrder') {
        return '请您支付补差金额'
      }
      if (e.messageCustomExt.type == 'renderProduct') {
        return '效果图'
      }

      if (e.messageCustomExt.type == 'submitChangeSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'demandSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'paySuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundOrder') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundChangeOrder') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundOrderSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundChangeOrderSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'subimtSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'designerChangeOrder') {
        if (e.messageCustomExt.status == 60) {
          return '请您确认修改需求'
        }
        if (e.messageCustomExt.status == 70) {
          return '改图制图中'
        }
        if (e.messageCustomExt.status == 50) {
          return '已完成'
        }
        if (e.messageCustomExt.status == 0) {
          return '请您确认修改需求'
        }
      }
    },
    getExtType(item) {
      return JSON.parse(item.messageExt).messageType
    },
    //获取对方名称
    getOtherName(item) {
      if (this.type == 'chat' && item.noteName != null) {
        return item.noteName
      }
      return item.otherInfo.nickname
    },
    
    /**
     * 将分转成元
     *
     * @param price 分，例如说 100 分
     * @returns {string} 元，例如说 1.00 元
     */
     fen2yuan(price) {
      return (price / 100.0).toFixed(2)
    },
    dateFormater(v) {
      return dateFormaterSession(v)
    },
    getimage(item) {
      let res = require('@/assets/pngfile.png')
      switch (item.split('.')[1]) {
        case 'png':
          res = require('@/assets/pngfile.png')
          break
        case 'doc':
          res = require('@/assets/wfile.png')
          break
        case 'xlsx':
          res = require('@/assets/xfile.png')
          break
        case 'dwg':
          res = require('@/assets/cadfile.png')
          break
        case 'zip':
          res = require('@/assets/zfile.png')
          break
        case 'pptx':
          res = require('@/assets/pfile.png')
          break
        default:
          break
      }
      return res
    },
    getMemberName(item) {
      let nameText = ''
      item.members.forEach(e => {
        if (item.messageFrom == e.id) {
          nameText = e.nickname
        }
      })
      return nameText
    },
    getTypes(v) {
      if (v.messageType == 'txt') {
        let textmsg = v.messageMsg
        if (textmsg.indexOf('http') == 0) {
          if (textmsg.indexOf('Goods_Goods') >= 0 || textmsg.indexOf('Supplier_Goods') >= 0) {
            //设计商品
            //普通商品
            return 'goods'
          } else if (textmsg.indexOf('Shop') >= 0) {
            //店铺
            return 'goods'
          } else if (textmsg.indexOf('renderingHall') >= 0) {
            //效果图
            return 'goods'
          } else {
            //网址
            return 'http'
          }
        }
      }
      return 'false'
    },
    getExtMsg(item) {
      let msg = JSON.parse(item.messageExt).messageMsg
      if (msg.indexOf('[') >= 0) {
        let msgs = msg
        //分析出所有表情
        for (const key in emMap) {
          if (msg.indexOf(key) >= 0) {
            for (var i = 0; i < 20; i++) {
              msgs = msgs.replace(key, '<image style="width:18px;height:18px;" src="' + ('https://cdn.binliyoujia.com/static/im/faces/' + emMap[key]) + '"/>')
            }
          }
        }
        return msgs
      } else {
        return msg
      }
    },
    getExtName(item) {
      let nameText = ''
      let ms = JSON.parse(item.messageExt)
      ms.members.forEach(e => {
        if (ms.messageFrom == e.id) {
          nameText = e.nickname
        }
      })
      return nameText
    },
    getExtCustom(item) {
      let ms = JSON.parse(item.messageExt)
      return this.getCustomTitle(ms)
    },
    getInfoCustom(item) {
      return this.getCustomTitle(item)
    },
    //获取扩展消息
    getExtMessages(item) {
      let forwardArr = []
      forwardArr = JSON.parse(item.messageExt).exts
      console.log(forwardArr)
      return forwardArr
    },
    getCustomTitle(e) {
      if (e.messageCustomExt.type == 'designerOrder') {
        if (e.messageCustomExt.status == 100) {
          return '订单已关闭'
        }
        if (e.messageCustomExt.status == 0) {
          return '请您确认支付'
        }
        if (e.messageCustomExt.status == 10) {
          return '已支付'
        }
        if (e.messageCustomExt.status == 20) {
          return '请您确认需求'
        }
        if (e.messageCustomExt.status == 30) {
          return '制图中'
        }
        if (e.messageCustomExt.status == 40) {
          return '请您确认图纸'
        }
        if (e.messageCustomExt.status == 50) {
          return '已完成'
        }
      }
      if (e.messageCustomExt.type == 'selectOrder') {
        return '商品价格改好了，可直接付款哦'
      }
      if (e.messageCustomExt.type == 'demandOrder') {
        return '我发送了一个设计需求，请尽快查看并回复哦~'
      }
      if (e.messageCustomExt.type == 'designerFixOrder') {
        return '请您支付补差金额'
      }
      if (e.messageCustomExt.type == 'renderProduct') {
        return '效果图'
      }

      if (e.messageCustomExt.type == 'submitChangeSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'demandSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'paySuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundOrder') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundChangeOrder') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundOrderSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundChangeOrderSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'subimtSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'designerChangeOrder') {
        if (e.messageCustomExt.status == 60) {
          return '请您确认修改需求'
        }
        if (e.messageCustomExt.status == 70) {
          return '改图制图中'
        }
        if (e.messageCustomExt.status == 50) {
          return '已完成'
        }
        if (e.messageCustomExt.status == 0) {
          return '请您确认修改需求'
        }
      }
    },
    redAudio(item, index) {
      item.redfy = 1
      this.$forceUpdate()
      if (item.redtext) {
        return
      }
      console.log(item.messageUrl)
      let that = this
      KDXF.redfile(item.messageUrl, redtext => {
        item.redtext = redtext
        that.$forceUpdate()
      })
    },
    startplayAudio(item, index) {
      if (this.audioPlayStatus[index] == 1) {
        console.log('暂停')
        this.armRec.pause()
        this.audioPlayStatus[index] = 2
        return
      } else if (this.audioPlayStatus[index] == 2) {
        console.log('继续播放')
        this.armRec.resume()
        this.audioPlayStatus[index] = 1
        return
      } else if (this.audioPlayStatus[index] == 0) {
        this.armRec.stop()
      } else {
        if (this.audioPlayStatus.length > 0) {
          this.armRec.stop()
        }
      }
      this.armRec = new BenzAMRRecorder()
      const src = item.messageUrl
      console.log('>>>>>开始播放音频', item.messageUrl)
      //初始化音频源并调用播放
      this.armRec.initWithUrl(src).then(() => {
        this.armRec.play()
        this.audioPlayStatus[index] = 1
      })
      //播放开始监听
      this.armRec.onPlay(() => {
        console.log('播放事件')
        // this.audioPlayStatus[index] = 1
      })
      //播放结束监听
      this.armRec.onPause(() => {
        this.audioPlayStatus[index] = 2
        console.log('暂停事件')
      })
      //播放结束监听
      this.armRec.onStop(() => {
        console.log('停止事件')
        this.audioPlayStatus[index] = 0
      })
    },
  },
}
</script>
<style scoped>
.message-body {
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
}
.message-body-my {
  display: flex;
  justify-content: flex-end;
  vertical-align: middle;
  margin-right: 22px;
}
.forward-session-icon {
  width: 14px;
  height: 14px;
}
.user-header {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user-header img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.text-plant {
  max-width: calc(100% - 200px);
}
.text-name {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  text-align: left;
  margin-left: 10px;
}
.text-name-my {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}
.text-body {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  background: #eeeeee;
  margin-top: 6px;
}

.text-body-my {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  background: #fff3e8;
  margin-top: 6px;
}
.text-body p {
  margin-bottom: 0px !important;
}
.text-body-my p {
  margin-bottom: 0px !important;
}
.quote-body {
  padding-left: 8px;
  text-align: left;
  font-size: 12px;
  color: #666;
  border-left: 4px solid #d8d8d8;
  margin-bottom: 8px;
}
.image-box {
  width: 100px;
}
.file-box {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.image-box-file {
  width: 54px;
}
.image-box-audio {
  width: 14px;
  cursor: pointer;
}
.custom-body {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
  padding: 8px;
  border-radius: 5px 5px 5px 5px;
  background-color: #fff;
}
.custom-img {
  width: 60px;
  height: 60px;
}
.custom-img img {
  width: 60px;
  height: 60px;
  border-radius: 5px 5px 5px 5px;
}
.custom-text {
  height: 60px;
  width: 180px;
  margin-left: 8px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.custom-name {
  width: 100px; /* 设置容器的宽度 */
  white-space: nowrap; /* 禁止文本换行 */
  overflow: hidden; /* 隐藏超出部分的内容 */
  text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
}
.custom-action {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.custom-cel {
  border-radius: 20px;
  height: 29px;
  width: 100px;
  box-sizing: border-box;
}
.custom-ok {
  border-radius: 20px;
  height: 29px;
  font-size: 12px;
  opacity: 1;
  background: linear-gradient(90deg, #ff6044 0%, #ed2616 100%);
  box-shadow: 0px 8px 8px 0px rgba(237, 38, 22, 0.1);
  border: none;
  color: #fff;
}
.custom-ok-cleal {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #999;
}
.custom-ok-cleal:hover {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #999;
  color: #ed2616 !important;
}
.custom-ok-cleal:active {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #999;
  color: #ed2616;
}
.custom-ok-cleal:focus {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #ed2616;
  color: #ed2616;
}
.note-right {
  width: 12px;
  height: 12px;
}
.note-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10rpx;
  border-top: 1px solid #eee;
  padding-top: 10px;
}
.note-button {
  color: #888;
  font-size: 10px;
}
.note-title {
  color: #222;
  font-weight: 600;
  font-size: 16px;
}
.note-content {
  color: #888;
  font-size: 12px;
  margin-top: 4px;
}
.message-time {
  text-align: left;
  margin-left: 42px;
  margin-top: 6px;
  font-family: "PingFangSC-Regular";
  font-size: 10px;
  font-weight: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999999;
}
.message-time-my {
  text-align: right;
  margin-right: 65px;
  margin-top: 6px;
  font-family: "PingFangSC-Regular";
  font-size: 10px;
  font-weight: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999999;
}

</style>