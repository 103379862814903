<template >
  <div v-if="showMessage" @contextmenu.prevent="openMenu($event, message)">
    <div :class="message.belong ? 'text-body-my' : 'text-body'">
      <!-- 通知显示 -->
      <!-- 改图订单退款成功通知 -->
      <div v-if="message.messageCustomExt.type == 'refundChangeOrderSuccess'">
        <div class="note-title">{{ message.messageCustomExt.title }}</div>
        <div class="note-content">
          {{ message.messageCustomExt.content }}
        </div>
        <div class="note-footer">
          <div class="note-button">查看详情</div>
          <img class="note-right" src="https://cdn.binliyoujia.com/static/message/forward-right.png" />
        </div>
      </div>
      <!-- 主订单退款成功通知 -->
      <div v-if="message.messageCustomExt.type == 'refundOrderSuccess'">
        <div class="note-title">{{ message.messageCustomExt.title }}</div>
        <div class="note-content">
          {{ message.messageCustomExt.content }}
        </div>
        <div class="note-footer">
          <div class="note-button">查看详情</div>
          <img class="note-right" src="https://cdn.binliyoujia.com/static/message/forward-right.png" />
        </div>
      </div>
      <!-- 改图退款申请通知 -->
      <div v-if="message.messageCustomExt.type == 'refundChangeOrder'">
        <div class="note-title">{{ message.messageCustomExt.title }}</div>
        <div class="note-content">
          {{ message.messageCustomExt.content }}
        </div>
        <div class="note-footer">
          <div class="note-button">查看详情</div>
          <img class="note-right" src="https://cdn.binliyoujia.com/static/message/forward-right.png" />
        </div>
      </div>
      <!-- 主订单退款申请通知 -->
      <div v-if="message.messageCustomExt.type == 'refundOrder'">
        <div class="note-title">{{ message.messageCustomExt.title }}</div>
        <div class="note-content">
          {{ message.messageCustomExt.content }}
        </div>
        <div class="note-footer">
          <div class="note-button">查看详情</div>
          <img class="note-right" src="https://cdn.binliyoujia.com/static/message/forward-right.png" />
        </div>
      </div>
      <!-- 支付成功通知 -->
      <div v-if="message.messageCustomExt.type == 'paySuccess'">
        <div class="note-title">{{ message.messageCustomExt.title }}</div>
        <div class="note-content">
          {{ message.messageCustomExt.content }}
        </div>
        <div class="note-footer">
          <div class="note-button">查看详情</div>
          <img class="note-right" src="https://cdn.binliyoujia.com/static/message/forward-right.png" />
        </div>
      </div>
      <!-- 改图订单支付成功通知 -->
      <div v-if="message.messageCustomExt.type == 'submitChangeSuccess'">
        <div class="note-title">{{ message.messageCustomExt.title }}</div>
        <div class="note-content">
          {{ message.messageCustomExt.content }}
        </div>
        <div class="note-footer">
          <div class="note-button">查看详情</div>
          <img class="note-right" src="https://cdn.binliyoujia.com/static/message/forward-right.png" />
        </div>
      </div>
      <!-- 补差支付成功通知 -->
      <div v-if="message.messageCustomExt.type == 'payFixSuccess'">
        <div class="note-title">{{ message.messageCustomExt.title }}</div>
        <div class="note-content">
          {{ message.messageCustomExt.content }}
        </div>
        <div class="note-footer">
          <div class="note-button">查看详情</div>
          <img class="note-right" src="https://cdn.binliyoujia.com/static/message/forward-right.png" />
        </div>
      </div>

      <!-- 确认需求通知 -->
      <div v-if="message.messageCustomExt.type == 'demandSuccess'">
        <div class="note-title">{{ message.messageCustomExt.title }}</div>
        <div class="note-content">
          {{ message.messageCustomExt.content }}
        </div>
        <div class="note-footer">
          <div class="note-button">查看详情</div>
          <img class="note-right" src="https://cdn.binliyoujia.com/static/message/forward-right.png" />
        </div>
      </div>
      <!-- 确认图纸通知 -->
      <div v-if="message.messageCustomExt.type == 'subimtSuccess'">
        <div class="note-title">{{ message.messageCustomExt.title }}</div>
        <div class="note-content">
          {{ message.messageCustomExt.content }}
        </div>
        <div class="note-footer">
          <div class="note-button">查看详情</div>
          <img class="note-right" src="https://cdn.binliyoujia.com/static/message/forward-right.png" />
        </div>
      </div>
      <!-- 确认改图需求通知 收费与免费 -->
      <div v-if="message.messageCustomExt.type == 'subimtChangeSuccess'">
        <div class="note-title">{{ message.messageCustomExt.title }}</div>
        <div class="note-content">
          {{ message.messageCustomExt.content }}
        </div>
        <div class="note-footer">
          <div class="note-button">查看详情</div>
          <img class="note-right" src="https://cdn.binliyoujia.com/static/message/forward-right.png" />
        </div>
      </div>

      <!-- 邀请下单 -->
      <div v-if="message.messageCustomExt.type == 'selectOrder'">
        <div>已帮您选择好商品规格，立即下单</div>
        <div class="custom-body">
          <div class="custom-img">
            <img :src="message.messageCustomExt.image" />
          </div>
          <div class="custom-text">
            <div class="custom-name">
              {{ getStringExt(message.messageCustomExt.name) }}
            </div>
          </div>
        </div>
      </div>
      <!-- 需求订单 -->
      <div v-if="message.messageCustomExt.type == 'demandOrder'">
        <div>我发送了一个设计需求，请尽快查看并回复哦~</div>
        <div class="custom-body">
          <div class="custom-text" style="height: auto;" >
            <div class="custom-name">
              服务类型:{{ getStringExt(message.messageCustomExt.demandType) }}
            </div>
            <div class="custom-name">
              房屋面积:{{ getStringExt(message.messageCustomExt.demandArea) }}㎡
            </div>
          </div>
          <div class="custom-action">
          <a-button type="default" class="custom-ok" @click.stop="openDemand(message.messageCustomExt.demandId)">查看详情</a-button>
        </div>
        </div>
      </div>
      <!-- 效果图 -->
      <div v-if="message.messageCustomExt.type == 'renderProduct'">
        <div class="custom-body">
          <div class="custom-img">
            <img :src="message.messageCustomExt.image" />
          </div>
          <div class="custom-text">
            <div class="custom-name">
              {{ getStringExt(message.messageCustomExt.name ? message.messageCustomExt.name : message.messageCustomExt.productName) }}
            </div>
          </div>
        </div>
        <div class="custom-action">
          <a-button type="default" class="custom-ok" >查看效果图</a-button>
        </div>
      </div>
      <!-- 设计商品 -->
      <div v-if="message.messageCustomExt.type == 'designerProduct'">
        <div class="custom-body">
          <div class="custom-img">
            <img :src="message.messageCustomExt.image" />
          </div>
          <div class="custom-text">
            <div class="custom-name">
              {{ getStringExt(message.messageCustomExt.name ? message.messageCustomExt.name : message.messageCustomExt.productName) }}
            </div>
            <div class="custom-price">
              ¥<span style="font-size: 14px">{{ fen2yuan(message.messageCustomExt.price) }}</span>
            </div>
          </div>
        </div>
        <div class="custom-action">
          <a-button type="default" class="custom-ok" >查看详情</a-button>
        </div>
      </div>
      <!-- 设计订单 -->
      <div v-if="message.messageCustomExt.type == 'designerOrder'">
        <div>
          {{ message.messageCustomExt.no ? message.messageCustomExt.no : message.messageCustomExt.orderNo }}
        </div>
        <div class="custom-body">
          <div class="custom-img">
            <img :src="message.messageCustomExt.image" />
          </div>
          <div class="custom-text">
            <div class="custom-name">
              {{ getStringExt(message.messageCustomExt.name ? message.messageCustomExt.name : message.messageCustomExt.productName) }}
            </div>
            <div class="custom-price">
              ¥<span style="font-size: 14px">{{ fen2yuan(message.messageCustomExt.price) }}</span>
            </div>
          </div>
        </div>
        <div class="custom-action">
          <a-button type="default" class="custom-ok" >{{ getCustomButton(message) }}</a-button>
        </div>
      </div>
      <!-- 设计改图订单 -->
      <div v-if="message.messageCustomExt.type == 'designerChangeOrder'">
        <div>
          {{ message.messageCustomExt.no ? message.messageCustomExt.no : message.messageCustomExt.orderNo }}
        </div>
        <div class="custom-body">
          <div class="custom-img">
            <img :src="message.messageCustomExt.image" />
          </div>
          <div class="custom-text">
            <div class="custom-name">
              {{ getStringExt(message.messageCustomExt.name ? message.messageCustomExt.name : message.messageCustomExt.productName) }}
            </div>
            <div class="custom-price">
              {{ fen2yuan(message.messageCustomExt.price) > 0 ? '¥' : '' }}<span style="font-size: 14px">{{ fen2yuan(message.messageCustomExt.price) > 0 ? fen2yuan(message.messageCustomExt.price) : '免费改图' }}</span>
            </div>
          </div>
        </div>
        <div class="custom-action">
          <a-button type="default" :class="message.messageCustomExt.actionStatus ? 'custom-ok' : 'custom-ok-cleal'" >{{ getCustomButton(message) }}</a-button>
        </div>
      </div>
      <!-- 设计补差订单 -->
      <div v-if="message.messageCustomExt.type == 'designerFixOrder'">
        <div>
          {{ message.messageCustomExt.no ? message.messageCustomExt.no : message.messageCustomExt.orderNo }}
        </div>
        <div class="custom-body">
          <div class="custom-img">
            <img :src="message.messageCustomExt.image" />
          </div>
          <div class="custom-text">
            <div class="custom-name">
              {{ getStringExt(message.messageCustomExt.name ? message.messageCustomExt.name : message.messageCustomExt.productName) }}
            </div>
            <div class="custom-price">
              {{ fen2yuan(message.messageCustomExt.price) > 0 ? '¥' : '' }}<span style="font-size: 14px">{{ fen2yuan(message.messageCustomExt.price) > 0 ? fen2yuan(message.messageCustomExt.price) : '免费改图' }}</span>
            </div>
          </div>
        </div>
        <div class="custom-action">
          <a-button type="default" class="custom-ok" >{{ getCustomButton(message) }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: 'CustomMessage',
  components: {},
  props: {
    message: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showMessage: false,
    }
  },
  mounted() {
    this.showMessage = true
  },
  methods: {
    openMenu(event,item){
        console.log("右键事件最底层")
        this.$emit('openMenu',{event:event,item:item})
    },
    //截取字符串长度
    getStringExt(e) {
      if (e && e.length > 24) {
        return e.substring(0, 24) + '...'
      }
      return e
    },
    /**
     * 将分转成元
     *
     * @param price 分，例如说 100 分
     * @returns {string} 元，例如说 1.00 元
     */
    fen2yuan(price) {
      return (price / 100.0).toFixed(2)
    },
     //获取自定义消息标题
     getCustomButton(e) {
      if (e.messageCustomExt.type == 'designerOrder') {
        if (e.messageCustomExt.status == 100) {
          return '订单已关闭'
        }
        if (e.messageCustomExt.status == 0) {
          return '请您确认支付'
        }
        if (e.messageCustomExt.status == 10) {
          return '已支付'
        }
        if (e.messageCustomExt.status == 20) {
          return '请您确认需求'
        }
        if (e.messageCustomExt.status == 30) {
          return '制图中'
        }
        if (e.messageCustomExt.status == 40) {
          return '请您确认图纸'
        }
        if (e.messageCustomExt.status == 50) {
          return '已完成'
        }
      }
      if (e.messageCustomExt.type == 'selectOrder') {
        return '商品价格改好了，可直接付款哦'
      }
      if (e.messageCustomExt.type == 'demandOrder') {
        return '我发送了一个设计需求，请尽快查看并回复哦~'
      }
      if (e.messageCustomExt.type == 'designerFixOrder') {
        return '请您支付补差金额'
      }
      if (e.messageCustomExt.type == 'renderProduct') {
        return '效果图'
      }

      if (e.messageCustomExt.type == 'submitChangeSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'demandSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'paySuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundOrder') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundChangeOrder') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundOrderSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundChangeOrderSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'subimtSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'designerChangeOrder') {
        if (e.messageCustomExt.status == 60) {
          return '请您确认修改需求'
        }
        if (e.messageCustomExt.status == 70) {
          return '改图制图中'
        }
        if (e.messageCustomExt.status == 50) {
          return '已完成'
        }
        if (e.messageCustomExt.status == 0) {
          return '请您确认修改需求'
        }
      }
    },
    openDemand(id){
      this.$emit('openDemand',id)
    }
  },
}
</script>
  <style scoped>
.text-body {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  background: #eeeeee;
  margin-top: 6px;
}
.text-body-my {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  background: #fff3e8;
  margin-top: 6px;
}
.text-body p {
  margin-bottom: 0px !important;
}
.text-body-my p {
  margin-bottom: 0px !important;
}
.note-right {
  width: 12px;
  height: 12px;
}
.note-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10rpx;
  border-top: 1px solid #eee;
  padding-top: 10px;
}
.note-button {
  color: #888;
  font-size: 10px;
}
.note-title {
  color: #222;
  font-weight: 600;
  font-size: 16px;
}
.note-content {
  color: #888;
  font-size: 12px;
  margin-top: 4px;
}
.custom-body {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
  padding: 8px;
  border-radius: 5px 5px 5px 5px;
  background-color: #fff;
}
.custom-img {
  width: 60px;
  height: 60px;
}
.custom-img img {
  width: 60px;
  height: 60px;
  border-radius: 5px 5px 5px 5px;
}
.custom-text {
  height: 60px;
  width: 180px;
  margin-left: 8px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.custom-name {
  width: 100px; /* 设置容器的宽度 */
  white-space: nowrap; /* 禁止文本换行 */
  overflow: hidden; /* 隐藏超出部分的内容 */
  text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
}
.custom-action {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.custom-cel {
  border-radius: 20px;
  height: 29px;
  width: 100px;
  box-sizing: border-box;
}
.custom-ok {
  border-radius: 20px;
  height: 29px;
  font-size: 12px;
  opacity: 1;
  background: linear-gradient(90deg, #ff6044 0%, #ed2616 100%);
  box-shadow: 0px 8px 8px 0px rgba(237, 38, 22, 0.1);
  border: none;
  color: #fff;
}
.custom-ok-cleal {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #999;
}
.custom-ok-cleal:hover {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #999;
  color: #ed2616 !important;
}
.custom-ok-cleal:active {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #999;
  color: #ed2616;
}
.custom-ok-cleal:focus {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #ed2616;
  color: #ed2616;
}
</style>