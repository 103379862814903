<template>
  <div v-if="showMessage">
    <div class="f-m-i-body-text" v-if="item.messageType == 'txt' && item.extType == 0" v-html="getMessageContent(item)"></div>
    <div class="f-m-i-body-text" v-if="item.messageType == 'txt' && item.extType == 1">
      <div class="quote-body" style="margin-top: 8px">
        <div>{{ getMemberName(item) }}:</div>
        <div style="display: flex; align-items: center" v-if="item.messageExt.messageType == 'txt'" v-html="getMessageContent(item.messageExt)"></div>
        <div style="display: flex; align-items: center" v-if="item.messageExt.messageType == 'audio'">[语音]{{ item.messageExt.messageAudioLength }}"</div>
        <div style="display: flex; align-items: center" v-if="item.messageExt.messageType == 'img'"><img style="max-height: 56px" :src="item.messageExt.messageUrl" /></div>
        <div style="display: flex; align-items: center" v-if="item.messageExt.messageType == 'file'"><img style="max-height: 32px; width: 32rpx" :src="getFileIcon(item.messageExt)" />{{ item.messageExt.messageFileName }}</div>
        <div style="display: flex; align-items: center" v-if="item.messageExt.messageType == 'video'"><img style="max-height: 56px" :src="item.messageExt.videoUrl" /></div>
        <div style="display: flex; align-items: center" v-if="item.messageExt.messageType == 'custom'">{{ getCustomType(item.messageExt) }}</div>
      </div>
      <div v-html="getMessageContent(item)"></div>
    </div>
    <div class="f-m-i-body-text jiabian" v-if="item.messageType == 'txt' && item.extType == 2" @click.stop="openForwardMessageListDailogByList(item)">
      <div style="font-size: 12px; color: #222">{{ item.messageMsg }}</div>
      <div style="margin-top: 2px" v-for="extItem in  item.messageExt" :key="extItem.id">
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'txt' && extItem.extType == 2">
          {{ getMemberName(extItem) }}:
          <div v-html="getMessageContent(extItem)"></div>
        </div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'txt' && extItem.extType == 1">
          <div class="quote-body">
            <div>{{ getMemberName(extItem.messageExt) }}:</div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'txt'" v-html="getMessageContent(extItem.messageExt)"></div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'audio'">[语音]{{ extItem.messageExt.messageAudioLength }}"</div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'img'"><img style="max-height: 56px" :src="extItem.messageExt.messageUrl" /></div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'file'"><img style="max-height: 32px; width: 32rpx" :src="getFileIcon(extItem.messageExt)" />{{ extItem.messageExt.messageFileName }}</div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'video'"><img style="max-height: 56px" :src="extItem.messageExt.videoUrl" /></div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'custom'">{{ getCustomType(extItem.messageExt) }}</div>
          </div>
        </div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'audio'">{{ getMemberName(item) }}:[语音]{{ extItem.messageAudioLength }}"</div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'img'">{{ getMemberName(item) }}:<img style="max-height: 56px" :src="extItem.messageUrl" /></div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'file'">{{ getMemberName(item) }}:<img style="max-height: 32px; width: 32rpx" :src="getFileIcon(extItem)" />{{ extItem.messageFileName }}</div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'video'">{{ getMemberName(item) }}:<img style="max-height: 56px" :src="extItem.videoUrl" /></div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'custom'">{{ getMemberName(item) }}:{{ getCustomType(extItem) }}</div>
      </div>
    </div>
    <div class="f-m-i-body-file" v-if="item.messageType == 'file'">
      <div class="f-m-i-body-f-left">
        <div class="f-m-i-body-f-l-text">{{ item.messageFileName }}</div>
        <div class="f-m-i-body-f-l-desc">{{ fileSizeFormat(item.messageFileLength) }}</div>
      </div>
      <img class="f-m-i-body-f-img" :src="getFileIcon(item.messageFileName)" />
    </div>
    <div class="f-m-i-body-img" v-if="item.messageType == 'img'">
      <previewImage class="image-box" :src="item.messageUrl" />
    </div>
    <div class="f-m-i-body-text" v-if="item.messageType == 'video'">
      <video :src="item.messageUrl" style="width: 200px" controls="controls"></video>
    </div>
    <div class="f-m-i-body-text" v-if="item.messageType == 'custom'" style="max-width:250px;">
      <CustomMessage :message="item" @openMenu="openMenu"></CustomMessage>
    </div>
  </div>
</template>
<script>
import fileSizeFormat from '@/utils/fileSizeFormat'
import emMap from '@/utils/em'
import previewImage from '@/components/elimage.vue'
import CustomMessage from '@/components/messageItem/customMessage.vue'
export default {
  components: {
    previewImage,
    CustomMessage,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fileSizeFormat: fileSizeFormat,
      armRec: null,
      audioPlayStatus: [],
      showMessage:false
    }
  },
  mounted() {
    this.initData(this.item)
    this.showMessage = true
  },
  methods: {
    openForwardMessageListDailogByList(item){
      this.$emit("openForwardMessageListDailogByList",item)
    },
    openMenu(){

    },
    initData(m) {
        if(m.extType == 2){
            if( typeof m.messageExt == "string"){
                m.messageExt = JSON.parse(m.messageExt).exts
                m.messageExt.forEach(e=>{
                    this.initData(e)
                })
            }
        }
        if(m.extType == 1){
            if( typeof m.messageExt == "string"){
                m.messageExt = JSON.parse(m.messageExt)
                this.initData(m.messageExt)
            }
        }
        if( typeof m.messageCustomExt == "string"){
            m.messageCustomExt = JSON.parse(m.messageCustomExt)
        }
    },
    getMemberName(body) {
      let nameText = ''
      body.members.forEach(e => {
        if (body.messageFrom == e.id) {
          nameText = e.nickname
        }
      })
      return nameText
    },
    getMessageContent(body) {
      let msg = body.messageMsg
      if (msg.indexOf('http') >= 0) {
        //网址
        return '<a herf="' + msg + '">' + msg + '</a>'
      }
      //表情包含替换
      for (const key in emMap) {
        if (msg.indexOf(key) >= 0) {
          msg = msg.replaceAll(key, '<image style="width:18px;height:18px;" src="' + ('https://cdn.binliyoujia.com/static/im/faces/' + emMap[key]) + '"/>')
        }
      }
      return msg
    },
    getFileIcon(item) {
      let fileExtension = item.substring(item.lastIndexOf('.') + 1)
      if (fileExtension == 'zip' || fileExtension == 'ZIP') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/zip.png'
      }
      if (fileExtension == 'doc' || fileExtension == 'docx' || fileExtension == 'DOC' || fileExtension == 'DOCX') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/word.png'
      }
      if (fileExtension == 'xls' || fileExtension == 'xlsx' || fileExtension == 'XLS' || fileExtension == 'XLSX') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/xls.png'
      }
      if (fileExtension == 'txt' || fileExtension == 'TXT') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/txt.png'
      }
      if (fileExtension == 'pdf' || fileExtension == 'PDF') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/pdf.png'
      }
      if (fileExtension == 'ppt' || fileExtension == 'pptx' || fileExtension == 'PPT' || fileExtension == 'PPTX') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/ppt.png'
      }
      if (fileExtension == 'dwg' || fileExtension == 'dxf' || fileExtension == 'DWG' || fileExtension == 'DXF') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/cad.png'
      }
    },
    getCustomType(e) {
      if (e.messageCustomExt.type == 'designerOrder') {
        if (e.messageCustomExt.status == 100) {
          return '[已关闭订单]'
        }
        if (e.messageCustomExt.status == 0) {
          return '[未支付订单]'
        }
        if (e.messageCustomExt.status == 10) {
          return '[已支付订单]'
        }
        if (e.messageCustomExt.status == 20) {
          return '[待确认需求订单]'
        }
        if (e.messageCustomExt.status == 30) {
          return '[制图中订单]'
        }
        if (e.messageCustomExt.status == 40) {
          return '[待确认图纸订单]'
        }
        if (e.messageCustomExt.status == 50) {
          return '[已完成订单]'
        }
      }
      if (e.messageCustomExt.type == 'selectOrder') {
        return '[商品价格改好了，可直接付款哦]'
      }
      if (e.messageCustomExt.type == 'demandOrder') {
        return '[我发送了一个设计需求，请尽快查看并回复哦~]'
      }
      if (e.messageCustomExt.type == 'designerFixOrder') {
        return '[补差订单]'
      }
      if (e.messageCustomExt.type == 'renderProduct') {
        return '[效果图]'
      }

      if (e.messageCustomExt.type == 'submitChangeSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'demandSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'paySuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'refundOrder') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'refundChangeOrder') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'refundOrderSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'refundChangeOrderSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'subimtSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'designerChangeOrder') {
        if (e.messageCustomExt.status == 60) {
          return '[改图待确认需求]'
        }
        if (e.messageCustomExt.status == 70) {
          return '[改图制图中]'
        }
        if (e.messageCustomExt.status == 50) {
          return '[已完成订单]'
        }
        if (e.messageCustomExt.status == 0) {
          return '[改图待确认需求(未支付)]'
        }
      }
    }
  },
}
</script>
<style scoped>
.f-m-i-body-text {
  font-size: 14px;
  color: #222;
}
.f-m-i-body-file {
  width: 243px;
  display: flex;
  border-radius: 10px;
  background: #fafafa;
  border: 1px solid rgba(34, 34, 34, 0.1);
  padding: 10px;
  justify-content: space-between;
  margin-top: 10px;
}
.f-m-i-body-f-left {
}
.f-m-i-body-f-l-text {
  color: #222;
  font-size: 16px;
}
.f-m-i-body-f-l-desc {
  color: #888;
  font-size: 12px;
  margin-top: 6px;
}
.f-m-i-body-f-img {
  width: 50px;
  height: 50px;
}
.f-m-i-body-img {
  max-width: 260px;
  border-radius: 5px;
}
.jiabian {
  padding: 4px 6px 4px 6px;
  font-size: 12px;
}
.image-box {
  width: 100px;
}
.quote-body {
  padding-left: 8px;
  text-align: left;
  font-size: 12px;
  color: #666;
  border-left: 4px solid #d8d8d8;
  margin-bottom: 8px;
}
</style>