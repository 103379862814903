import dateFormat from '@/utils/dateFormater'
export function dateFormaterSession(t) {
    const today = new Date()
    const date = new Date(t)
    
    if(date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()){
        //判断时间戳是否是今天
        return dateFormat('HH:mm',t)
    }else if((date.getDate()-1) == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()){
        //判断时间戳是否是昨天
        return "昨天"+dateFormat('HH:mm',t)
    }else{
        //昨天之前
        return dateFormat('YYYY/MM/DD HH:mm',t);
    }
}

export default dateFormaterSession
